import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const reviewsNumberOfQuestionsFragment = `
    on ${QueryType.product} {
        reviews {
            number_of_questions
        }
    }
`;

export const reviewsNumberOfQuestionsFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: reviewsNumberOfQuestionsFragment
  };
};
