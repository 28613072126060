import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const taxExcludedPriceFragment = `
    on ${QueryType.skus} {
        prices {
            exclude_tax {
                price
                original_price
                price_per_unit
                price_formatted
                original_price_formatted
                price_per_unit_formatted
            }
        }
    }
`;

export const taxExcludedPriceFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: taxExcludedPriceFragment
  };
};
