import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skinTypeFragment = `
    on ${QueryType.skus} {
        skin {
            type {
                key
                value
            }
        }
    }
`;

export const skinTypeFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skinTypeFragment
  };
};
