import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productVideosFragment = `
    on ${QueryType.product} {
        media {
            videos {
                position
                src
                provider
                poster {
                    src
                    height
                    width
                }
            }
        }
    }
`;

export const productVideosFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productVideosFragment
  };
};
