import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import {
  DataSource,
  getIdsFromData,
  getProducts,
  getProductsByCategoryId,
  getProductsByTagName,
  IConfigItem,
  IItemType,
  ProductType
} from '@gemini/shared/services/products/catalog';
import {
  PRODUCTS_BY_SKU_ID,
  PRODUCTS_BY_TAG_ID,
  PRODUCTS_BY_TAG_NAME
} from '@gemini/shared/services/products/next-sd-prodcat';
import { getLogger } from '@gemini/shared/utils/logger';
import { getDefaultRetryOptions, retry } from '@gemini/shared/utils/retry';

export const isDataSourcePerlgem = () =>
  process.env.PRODUCT_CATALOG_DATA_SOURCE === DataSource.PERLGEM;
export const isDataSourceStardust = () =>
  process.env.PRODUCT_CATALOG_DATA_SOURCE === DataSource.STARDUST;

const getProductIdsFromSkus = async (skuIds: string[]) => {
  const skuProducts = await retry(
    () =>
      getProducts({ skuIds }, PRODUCTS_BY_SKU_ID, {
        updateInventory: false
      }),
    getDefaultRetryOptions('getProducts')
  )();

  return skuProducts ? skuProducts.map(({ productId }) => productId) : [];
};

const getCrossSellOnPerlgem = async (ids: string[]) => {
  const crossSellProducts = await retry(
    () =>
      getProducts(
        {
          skuIds: ids
        },
        '',
        {
          updateInventory: false,
          queryKey: 'catalog-spp'
        }
      ),
    getDefaultRetryOptions('getProducts')
  )();

  return crossSellProducts;
};
type CrossSellFetchParams = {
  query: string;
  shouldUpdateInventory: boolean;
};

const getCrossSellOnStardust = async (
  ids: string[],
  options: CrossSellFetchParams
) => {
  const productIds = await getProductIdsFromSkus(ids);
  let products: ProductType[] = [];

  if (productIds && productIds.length) {
    products = await retry(
      () =>
        getProducts({ productIds }, options.query, {
          updateInventory: options.shouldUpdateInventory,
          queryKey: 'catalog-spp'
        }),
      getDefaultRetryOptions('getProducts')
    )();
  }

  return products;
};

export const getCrossSellProducts = async (
  crossSellSkuIds: string[],
  options: CrossSellFetchParams
) => {
  let crossSellProducts: ProductType[] = [];

  if (isDataSourcePerlgem()) {
    crossSellProducts = await getCrossSellOnPerlgem(crossSellSkuIds);
  } else if (isDataSourceStardust()) {
    crossSellProducts = await getCrossSellOnStardust(crossSellSkuIds, options);
  }

  return JSON.parse(JSON.stringify(crossSellProducts));
};

// product-grid
export const getProductDataFromProps = async ({
  data
}: TemplateType): Promise<string[][]> => {
  const fetchPromises: Promise<string[]>[] = [];

  const {
    productIds: itemsIds,
    skuIds,
    tagIds,
    tagNames
  } = getIdsFromData({
    items: data.config.items,
    productTags: data.config.productTags
  });

  if (skuIds.length || itemsIds.length) {
    let skuProductIds: string[] = [];

    if (skuIds.length) {
      skuProductIds = await getProductIdsFromSkus(skuIds);
    }

    const productsIds = !skuProductIds
      ? itemsIds
      : skuProductIds.concat(itemsIds);

    if (productsIds.length) {
      fetchPromises.push(Promise.resolve(productsIds));
    }
  } else {
    if (tagNames.length) {
      fetchPromises.push(
        retry(
          () => getProductsByTagName({ tags: tagNames }, PRODUCTS_BY_TAG_NAME),
          getDefaultRetryOptions('getProductsByTagsName')
        )()
      );
    }

    if (tagIds.length) {
      fetchPromises.push(
        retry(
          () =>
            getProductsByCategoryId(
              { categoryIds: tagIds },
              PRODUCTS_BY_TAG_ID
            ),
          getDefaultRetryOptions('getProductsByCategoryIds')
        )()
      );
    }
  }

  const results = await Promise.allSettled(fetchPromises);

  return results.map((result) => {
    if (result.status === 'rejected') {
      getLogger<true>().logError(result.reason);

      return [];
    } else {
      return result.value;
    }
  });
};

const isTemplateGrid = (template: TemplateType) =>
  template.component === 'ServiceView' &&
  template.metadata.serviceName === 'elc-service-prodcat' &&
  template.metadata.serviceViewName === 'product-grid';

type TabbedBlockData = {
  tabs: { blockReference: { data: { templates: TemplateType[] } } }[];
};

const isTemplateTabbedBlock = (template: TemplateType) =>
  template.component === 'TabbedBlock';

const getGridsFromTemplates = (templates: TemplateType[]) =>
  templates.reduce((grids: TemplateType[], template) => {
    if (isTemplateGrid(template)) {
      grids.push(template);
    } else if (isTemplateTabbedBlock(template)) {
      const dGrids = (template.data as TabbedBlockData)?.tabs.map((tab) =>
        getGridsFromTemplates(tab?.blockReference?.data?.templates)
      );
      dGrids.flat(5).forEach((g) => grids.push(g));
    } else if (template.data?.templates) {
      const dGrids = getGridsFromTemplates(template.data?.templates);

      dGrids.flat(3).forEach((g) => grids.push(g));
    }

    return grids;
  }, []);

export const getIdsForPLP = async (templates: TemplateType[]) => {
  const gridServiceViews = getGridsFromTemplates(templates);
  const resolvedPromises = await Promise.all(
    gridServiceViews.map(getProductDataFromProps)
  );

  resolvedPromises.forEach((viewData, index) => {
    viewData.forEach((ids) => {
      const currentGrid = gridServiceViews[index];

      currentGrid.data.config.items = (currentGrid.data.config.items ||
        ids.map((id) => ({
          type: IItemType.PRODUCT,
          params: {
            id
          }
        }))) as IConfigItem[];

      if (currentGrid.dataMobile?.config) {
        currentGrid.dataMobile.config.items = currentGrid.data.config.items;
      }
    });
  });

  return resolvedPromises.flat(10);
};
