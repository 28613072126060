import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const subDisplayNameFragment = `
    on ${QueryType.product} {
        sub_display_name
    }
`;

export const subDisplayNameFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: subDisplayNameFragment
  };
};
