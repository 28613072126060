import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const locationFragment = `
    on ${QueryType.skus} {
        location {
            key
            value
        }
    }
`;

export const locationFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: locationFragment
  };
};
