import {
  jsonRPCFetch,
  jsonRPCFetchResponse
} from '@gemini/shared/services/utils/pg-jsonrpc';

export const DATALAYER_GET_METHOD = 'analytics.getDataLayer';

function filterPgDataLayer(dataLayer: any) {
  // remove nulls and undefined values from the data layer
  return dataLayer
    ? Object.entries(dataLayer).reduce(
        (acc: any, [key, val]) => (val == null ? acc : ((acc[key] = val), acc)),
        {}
      )
    : {};
}

export async function getPgDataLayer() {
  const request: any = [];

  request.push({
    method: DATALAYER_GET_METHOD,
    params: []
  });

  const url = `/api/rpc/getDataLayer?dbgmethod=${DATALAYER_GET_METHOD}`;

  const res = await jsonRPCFetch({
    url,
    request,
    useCSRFTokenParam: true
  });

  const result = await jsonRPCFetchResponse(res as Response);

  return filterPgDataLayer(result || {});
}
