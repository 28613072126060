import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuResponsiveImagesFragment = `
    on ${QueryType.skus} {
        media {
            responsive {
                alt
                height
                position
                src
                type
                width
            }
        }
    }
`;

export const skuResponsiveImagesFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuResponsiveImagesFragment
  };
};
