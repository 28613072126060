import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productReviewsFragment = `
    on ${QueryType.product} {
        reviews {
            average_rating
            number_of_reviews
        }
    }
`;

export const productReviewsFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productReviewsFragment
  };
};
