import { IQueryTypeFragments } from '../QueryFragments';
import { QueryType } from './Default';

const mandatoryFragment = `
    on ${QueryType.collection} {
        items {
            product_id
            skus {
                items {
                    inventory_status
                    sku_id
                }
            }
        }
    }
`;

export const mandatoryFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.collection]: mandatoryFragment
  };
};
