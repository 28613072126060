export type ServiceViewType = {
  data?: any;
  metadata: {
    [key: string]: any;
    serviceName: string;
    serviceViewName: string;
    serviceVersion?: string;
    templateSource?: string;
  };
};

export default function ServiceViewRenderer({
  data,
  metadata
}: ServiceViewType) {
  return (
    <div
      className="elc-service-view"
      data-component={metadata.serviceViewName}
      data-config={JSON.stringify(data.config)}
      data-render-method={data.renderMethod}
      data-version={metadata.serviceVersion}
    />
  );
}
