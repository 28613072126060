import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skinGoalFragment = `
    on ${QueryType.product} {
        skin_goal {
            key
            value
        }
    }
`;

export const skinGoalFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: skinGoalFragment
  };
};
