import { getLogger } from '@gemini/shared/utils/logger';

type RetryOptions = {
  retryAmount: number;
  awaitMilliseconds?: number;
  functionName: string;
};

type AnyPromise<T> = (...args: unknown[]) => Promise<T>;

export const getDefaultRetryOptions = (fnName: string) => ({
  retryAmount: 2,
  awaitMilliseconds: 250,
  functionName: fnName
});

export const retry = <T>(
  fn: AnyPromise<T>,
  options: RetryOptions
): AnyPromise<T> => {
  const defaultOpt = getDefaultRetryOptions('');

  const {
    awaitMilliseconds = defaultOpt.awaitMilliseconds,
    retryAmount = defaultOpt.retryAmount
  } = options;

  if (retryAmount < 1) {
    throw new Error('Minimum retryAmount option must be 1');
  }

  let amount = 0;

  const getErrorMsg = (tried: number, max: number) =>
    `Error at retry method (tried: ${tried}, max: ${max}).`;

  const run = async (...args: unknown[]): Promise<T> => {
    try {
      if (amount === retryAmount) {
        throw new Error(`Failed at max retry, fn ${options.functionName}`);
      }

      amount++;

      let _fn = fn;

      if (awaitMilliseconds && amount > 1) {
        _fn = () =>
          new Promise<T>((resolve) => {
            setTimeout(async () => {
              try {
                const value = await fn(args);

                return resolve(value);
              } catch (error: unknown) {
                const message = getErrorMsg(amount, retryAmount).concat(
                  (error as Error).message
                );
                getLogger().logError(error as Error, message);

                return run(args);
              }
            }, awaitMilliseconds);
          });
      }

      const fnValue = await _fn(args);

      return fnValue;
    } catch (error: unknown) {
      const message = getErrorMsg(amount, retryAmount).concat(
        (error as Error).message
      );
      getLogger().logError(error as Error, message);

      if (amount === retryAmount) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // This would be an error
        return;
      }

      return run(args);
    }
  };

  return run;
};
