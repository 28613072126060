import { useUpdateAtom } from 'jotai/utils';
import Script from 'next/script';
import { useEffect } from 'react';
import {
  serviceBusOn,
  ServiceBusTopics
} from '@gemini/shared/services/communication/next-elc-service-bus';
import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { root } from '@gemini/shared/services/configuration/utils';
import {
  isBrowser,
  isCartOverlayDrawer,
  isMFEnabled
} from '@gemini/shared/services/utils/global';
import {
  getBundleUrl,
  ReplaceSrc
} from '@gemini/shared/services/utils/js-repo';
import { createInitAtom } from '@gemini/shared/ui/utils/init';

export const EcommStarted = createInitAtom<boolean>(false, 'ecomm_started');
export const SESSION_MGMT_SERVICE = 'elc-service-session-mgmt';
export const ECOMM_APP = 'elc-ecomm';

if (isBrowser()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  root.Buffer = root.Buffer || require('buffer').Buffer;
}

const RUNTIME_LIB = 'elc-runtime@4.x.x';
const ECOMM_LIB = `${ECOMM_APP}@3.x.x`;
const ECOMM_DEPENDENCIES = [
  'elc-component-renderer@3.x.x',
  'elc-service-config-manager@5.x.x',
  'pg-service-inventory@1.x.x',
  `${SESSION_MGMT_SERVICE}@2.x.x`,
  'elc-service-prodcat@6.x.x',
  'elc-service-content@4.x.x',
  'elc-service-translations@5.x.x',
  'elc-service-communications@5.x.x',
  'elc-service-vto@3.x.x'
  // 'elc-service-ratings-reviews@3.x.x',
  // 'elc-service-stores@1.x.x',
  // 'elc-service-appt-booking@1.x.x',
  // 'elc-service-product-ugc@3.x.x',
  // 'elc-service-subscriptions@1.x.x',
  // 'pg-service-cart@2.x.x'
  // 'pg-service-offers@1.x.x',
  // 'pg-service-user-profile@x.x.x',
  // 'pg-service-order-history@x.x.x',
  // 'elc-service-account@x.x.x'
];

export const SD_DEPENDENCIES =
  ECOMM_DEPENDENCIES.concat(RUNTIME_LIB).concat(ECOMM_LIB);

switch (process.env.NEXT_PUBLIC_SEARCH_DATA_SOURCE) {
  case 'stardust':
  default:
    ECOMM_DEPENDENCIES.push('elc-service-search@4.x.x');
    break;
  case 'attraqt':
    ECOMM_DEPENDENCIES.push('elc-service-discovery@1.x.x');
    break;
}

if (isCartOverlayDrawer()) {
  ECOMM_DEPENDENCIES.push('pg-service-cart@2.x.x');
}

export const ElcRuntime = () => {
  if (isMFEnabled()) {
    return (
      <Script
        key="runtime"
        src="/runtime/index.js"
        strategy="beforeInteractive"
      />
    );
  }

  const bundlePath = getBundleUrl(RUNTIME_LIB);

  return (
    <Script key={bundlePath} src={bundlePath} strategy="beforeInteractive" />
  );
};

const EcommDependencies = () =>
  ECOMM_DEPENDENCIES.map((bundle) => (
    <Script
      key={bundle}
      src={getBundleUrl(bundle)}
      strategy="beforeInteractive"
    />
  ));

export const EcommListener = () => {
  const updateAtom = useUpdateAtom(EcommStarted.atomInstance);

  useEffect(() => {
    if (isBrowser()) {
      serviceBusOn(
        ServiceBusTopics.events.ECOMM_STARTED,
        () => updateAtom(true),
        { replay: true }
      );
    }
  }, []);

  return null;
};

const EcommApp = () => {
  const bundle = getBundleUrl(ECOMM_LIB);
  const { appConfig: config } = useAppContext();

  return (
    <Script
      key={bundle}
      src={bundle}
      strategy="afterInteractive"
      onLoad={async () => {
        const ecomm = new root.EcommApp();
        await ecomm.start(config);
      }}
    />
  );
};

export const EcommScripts = () => (
  <>
    {ReplaceSrc()}
    {EcommDependencies()}
    {EcommApp()}
  </>
);

export default EcommScripts;
