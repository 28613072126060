export {
  getCookie,
  setCookie,
  getUserCartData,
  getCartItemCount,
  getFavItemCount,
  getSignedInStatus,
  getFirstName,
  getLoyaltyMemberStatus,
  getLoyaltyPoints,
  getLoyaltyLevelName,
  getCountryFromCookies,
  getLoyaltyLevel
} from './lib/BrowserStorage';
