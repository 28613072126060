import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const recipientFragment = `
    on ${QueryType.skus} {
        recipient {
            key
            value
        }
    }
`;

export const recipientFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: recipientFragment
  };
};
