import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const vtoFoundationFragment = `
    on ${QueryType.skus} {
        vto {
            is_foundation_experience
        }
    }
`;

export const vtoFoundationFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: vtoFoundationFragment
  };
};
