import https from 'https';
import {
  isBrowser,
  responseHandler
} from '@gemini/shared/services/utils/global';

export async function mantleCMSFetch(url: string) {
  const httpsAgent = new https.Agent({
    rejectUnauthorized: false
  });

  const headers: {
    [key: string]: any;
  } = new Headers();
  if (!isBrowser()) {
    headers.append(
      'cookie',
      `${headers.cookie}; ${process.env.NEXT_COOKIE_BYPASS_WAF}; ${process.env.NEXT_COOKIE_BYPASS_AKAMAI}`
    );
  }

  if (process.env.NEXT_PUBLIC_MANTLE_CMS_AUTH) {
    headers.append(
      'Authorization',
      `Basic ${process.env.NEXT_PUBLIC_MANTLE_CMS_AUTH}`
    );
  }

  const params = {
    headers: headers,
    method: 'GET',
    agent: httpsAgent,
    type: 'application/json'
  };

  const res = await fetch(url, params);

  const data = await responseHandler(url, res);

  return data;
}
