import Script from 'next/script';
import { useEffect, useMemo, useState } from 'react';
import { generateInitialDataLayer } from '../data-layer/dataLayer';
import { updateDataLayer } from '../data-layer/updateDataLayer';

function getTealiumUrl() {
  return `//${process.env.NEXT_PUBLIC_TEALIUM_DOMAIN}/utag/esteelauder/${process.env.NEXT_PUBLIC_TEALIUM_PROFILE}/${process.env.NEXT_PUBLIC_ANALYTICS_ENV}/utag.js`;
}

export interface IPostLoadConfig {
  dataLayer: any;
}

export interface ITealiumScriptProps {
  getDataLayer: () => Promise<any>;
  postLoad?(config: IPostLoadConfig): void;
}

export function TealiumScript({ getDataLayer, postLoad }: ITealiumScriptProps) {
  if (process.env.NEXT_PUBLIC_ANALYTICS_KILLSWITCH === 'true') {
    return null;
  }

  const [dataLayer, setDataLayer] = useState(null);

  const initialDataLayer = useMemo(() => generateInitialDataLayer(), []);

  useEffect(() => {
    const init = async () => {
      const reqDataLayer = await getDataLayer();
      const dl = { ...initialDataLayer, ...reqDataLayer };
      setDataLayer(dl);
      updateDataLayer(dl);
      postLoad?.({ dataLayer: dl });
    };
    void init();
  }, []);

  return dataLayer ? (
    <>
      <Script
        id="tealiumLoader"
        strategy="afterInteractive"
        src={getTealiumUrl()}
      />
    </>
  ) : null;
}

export default TealiumScript;
