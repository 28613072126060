import { mantleCMSFetch } from '@gemini/shared/services/content/next-mantlecms-server';

type ObjectString = {
  [key: string]: ObjectString | string;
};

interface ILanguage {
  ISO: string;
  label: string;
  region: string;
}

interface ICountry {
  ISO: string;
  label: string;
  name: string;
}
export interface IWrapperData {
  [key: string]: any;
  app_config: { [key: string]: any };
  countries?: ICountry[];
  languages?: ILanguage[];
  libs?: string[];
  site_config: { [key: string]: any };
  translations?: ObjectString;
  templates?: ObjectString;
}

/**
 * Fetch wrapper data from api
 * @returns {Object} wrapperData
 */
export async function getWrapperData(): Promise<IWrapperData> {
  const url = `${process.env.MANTLE_CMS_URL}/ecom-api/wrapper`;

  return mantleCMSFetch(url) as Promise<IWrapperData>;
}
