import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const shadeImageFragment = `
    on ${QueryType.skus} {
        shades {
            image {
                src
                height
                width
            }
        }
    }
`;

export const shadeImageFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: shadeImageFragment
  };
};
