import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const vtoMakeupFragment = `
    on ${QueryType.skus} {
        vto {
            is_color_experience
        }
    }
`;

export const vtoMakeupFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: vtoMakeupFragment
  };
};
