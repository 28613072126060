import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuModelImagesFragment = `
    on ${QueryType.skus} {
        media {
            model {
                src
                alt
                type
            }
        }
    }
`;

export const skuModelImagesFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuModelImagesFragment
  };
};
