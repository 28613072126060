import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const perlgemFragment = `
    on ${QueryType.skus} {
        perlgem {
            SKU_BASE_ID
        }
    }
`;

export const perlgemFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: perlgemFragment
  };
};
