import Script from 'next/script';
import { root } from '@gemini/shared/services/configuration/utils';
import { getBundleUrl } from '@gemini/shared/services/utils/js-repo';
import { getLogger } from '@gemini/shared/utils/logger';
import {
  IGlobalServiceBus,
  IServiceBusTopics,
  OptionsType
} from './types/ServiceBusTypes';

const SERVICE_BUS = 'elc-service-bus@2.x.x';

const isServiceBusAvailable = () =>
  !!(root as unknown as { GlobalServiceBus?: IGlobalServiceBus })
    .GlobalServiceBus;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IEventListener = (payload?: any) => any;
export type IEventListenerCleanup = () => void;

const eventDispatchersMap: Map<string, IEventDispatcher> = new Map();

interface IEventDispatcher {
  callback: IEventListener;

  addListener(listener: IEventListener): IEventListenerCleanup;
}

class EventDispatcher implements IEventDispatcher {
  private readonly listeners: IEventListener[] = [];

  public addListener(listener: IEventListener): IEventListenerCleanup {
    this.listeners.push(listener);

    return () => {
      const idx = this.listeners.indexOf(listener);
      if (idx >= 0) {
        this.listeners.splice(idx, 1);
      }
    };
  }

  public callback: IEventListener = (...args) => {
    this.listeners.forEach((l) => {
      try {
        l(...args);
      } catch (err) {
        getLogger().logError(err as Error);
      }
    });
  };
}

const getGlobalServiceBus = () =>
  (root as unknown as { GlobalServiceBus: IGlobalServiceBus }).GlobalServiceBus;

const getServiceBusTopics = () =>
  (root as unknown as { ServiceBusTopics: IServiceBusTopics }).ServiceBusTopics;

const getOrCreateEventDispatcher = (event: string, options: OptionsType) => {
  const dispatcherKey = `${event}_replay_${!!options.replay}`;
  let dispatcher = eventDispatchersMap.get(dispatcherKey);
  if (!dispatcher) {
    dispatcher = new EventDispatcher();
    eventDispatchersMap.set(dispatcherKey, dispatcher);

    if (isServiceBusAvailable()) {
      getGlobalServiceBus().on(event, dispatcher.callback, options);
    }
  }

  return dispatcher;
};

export async function serviceBusQuery<T = void>(
  name: string,
  payload?: unknown
): Promise<T> {
  return isServiceBusAvailable()
    ? getGlobalServiceBus().query<T>(name, payload)
    : Promise.reject(new Error('service bus not available'));
}

export const serviceBusEmit: IGlobalServiceBus['emit'] = (...args) => {
  if (isServiceBusAvailable()) {
    getGlobalServiceBus().emit(...args);
  }
};

export const serviceBusCommand: IGlobalServiceBus['command'] = (...args) => {
  if (isServiceBusAvailable()) {
    getGlobalServiceBus().command(...args);
  }
};

export const serviceBusOn = (
  ...args: Parameters<IGlobalServiceBus['on']>
): IEventListenerCleanup => {
  const [event, callback, options = { replay: false }] = args;

  const dispatcher = getOrCreateEventDispatcher(event, options);

  return dispatcher.addListener(callback);
};

export const ServiceBusTopics: IServiceBusTopics = {
  get commands() {
    return getServiceBusTopics().commands;
  },
  get events() {
    return getServiceBusTopics().events;
  },
  get queries() {
    return getServiceBusTopics().queries;
  }
};

export const ServiceBus = () => {
  return (
    <Script
      key={SERVICE_BUS}
      src={getBundleUrl(SERVICE_BUS)}
      strategy="beforeInteractive"
    />
  );
};
