import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const sizeDescriptionFragment = `
    on ${QueryType.skus} {
        size_description
    }
`;

export const sizeDescriptionFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: sizeDescriptionFragment
  };
};
