import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const finishOptionsFragment = `
    on ${QueryType.skus} {
        finish_options {
            key
            value
        }
    }
`;

export const finishOptionsFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: finishOptionsFragment
  };
};
