import { trackAddToCart } from './trackAddToCart';
import { trackEmailSignup } from './trackEmailSignup';

const PG_TO_STARDUST_EVENT_MAPPING: any = {
  addToCart: trackAddToCart,
  emailSignup: trackEmailSignup
};

export function routeEvent(eventName: string, eventData?: any) {
  if (typeof PG_TO_STARDUST_EVENT_MAPPING[eventName] === 'function') {
    PG_TO_STARDUST_EVENT_MAPPING[eventName](eventData);
  }
}
