import { createContext, useContext } from 'react';

export interface IAppContext {
  allowedPaths: string[];
  appConfig: any;
  brand: any;
  checkout: any;
  countries: [];
  country: any;
  i18n: any;
  js_settings: any;
  language: any;
  languages: any;
  revisionTags?: any;
  siteConfig: any;
  templates: any;
  translations: any;
  features: {
    [key: string]: {
      enabled: boolean;
    };
  };
}

export const defaultAppContext: IAppContext = {
  allowedPaths: [],
  appConfig: {
    subscriptionsConfig: {
      autoReplenishment: {
        enabled: false,
        intervals: []
      }
    }
  },
  brand: {
    literal: null
  },
  checkout: {
    validation: {
      currency: null,
      item_max_quantity: null
    }
  },
  countries: [],
  country: {
    abbrev: null
  },
  i18n: { locale: null },
  js_settings: {
    fb_login_app_id: null
  },
  language: {
    abbrev: null
  },
  languages: [],
  translations: {
    product: {},
    elc_general: {},
    elc_product: {},
    elc_search: {},
    elc_cart: {}
  },
  templates: {},
  features: {},
  siteConfig: {
    power_reviews: {
      production: {
        hide_add_answer_button: false
      }
    },
    gdpr_compliant: false
  }
};

export const AppContext = createContext(defaultAppContext);

export const AppProvider = ({ value, children }: any) => {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useAppContext() {
  return useContext(AppContext);
}
