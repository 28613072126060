/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { getAttraqtProducts } from '@gemini/shared/services/products/next-attraqt';
import {
  getPerlgemInventory,
  getPerlgemProducts
} from '@gemini/shared/services/products/next-pg-prodcat';
import {
  getStardustProductData,
  getStardustProductDataByCategoryId,
  getStardustProductDataByTagName
} from '@gemini/shared/services/products/next-sd-prodcat';
import { getLogger } from '@gemini/shared/utils/logger';
import ProductType from '../types/product';

export enum DataSource {
  ATTRAQT = 'attraqt',
  STARDUST = 'stardust',
  PERLGEM = 'perlgem'
}

export type Options = {
  updateInventory?: boolean;
  queryKey?: string;
};

export type QueryParams = {
  categoryIds?: string[];
  productIds?: string[];
  skuIds?: string[];
};

export const getProducts = async (
  params: QueryParams,
  query: string,
  options?: Options
) => {
  const { updateInventory, queryKey } = {
    updateInventory: true,
    ...options
  };
  const { categoryIds = [], productIds = [], skuIds = [] } = params;

  let products: ProductType[] = [];
  const dataSrc = process.env.PRODUCT_CATALOG_DATA_SOURCE;

  if (dataSrc === DataSource.PERLGEM) {
    products = await getPerlgemProducts({
      queryKey,
      variables: { categoryIds, productIds, skuIds }
    });
  }

  if (dataSrc === DataSource.ATTRAQT) {
    products = await getAttraqtProducts({
      variables: { categoryIds, productIds }
    });
  }

  if (dataSrc === DataSource.STARDUST) {
    products = await getStardustProductData({
      query,
      variables: {
        productIds: [
          ...productIds,
          '38242', // @todo derive these ids in pageDataFetch
          '6509',
          '97250',
          '25956',
          '97260',
          '9518',
          '22830'
        ],
        skuIds
      }
    });

    if (products && updateInventory) {
      try {
        const availableProductIds = products.map(
          ({ productId }) => `PROD${productId}`
        );

        const inventoryData = await getPerlgemInventory({
          query: 'product_inventory',
          variables: { productIds: availableProductIds }
        });

        products.forEach((product) => {
          const skuInventory = inventoryData.find(
            ({ product_id }) => product_id === product.productId
          )?.skus;

          product.skus.forEach((sku) => {
            const updatedInventory = skuInventory?.find(
              ({ sku_id }) => sku_id === sku.skuId
            );
            if (updatedInventory) {
              sku.inventoryStatus = updatedInventory.inventory_status;
            }
          });
        });
      } catch (error) {
        getLogger().logError(error as Error, 'Failed updating inventory data');
      }
    }
  }

  return products;
};

type CategoryQueryParams = {
  categoryIds: string[];
};

export const getProductsByCategoryId = async (
  { categoryIds = [] }: CategoryQueryParams,
  query: string
) => {
  if (process.env.PRODUCT_CATALOG_DATA_SOURCE === DataSource.STARDUST) {
    const products = await getStardustProductDataByCategoryId({
      query,
      /**
       * Make environment dynamic
       */
      variables: { catIds: categoryIds, environment: { prod: true } }
    });

    return products;
  }

  return [];
};

type TagsQueryParams = {
  tags: string[];
};

export const getProductsByTagName = async (
  { tags = [] }: TagsQueryParams,
  query: string
) => {
  if (process.env.PRODUCT_CATALOG_DATA_SOURCE === DataSource.STARDUST) {
    const products = await getStardustProductDataByTagName({
      query,
      /**
       * Make environment dynamic
       */
      variables: { keys: tags || [], environment: { prod: true } }
    });

    return products;
  }

  return [];
};
