import { IQueryTypeFragments } from '../QueryFragments';
import { QueryType } from './Default';

const skuCollectionFragment = `
    on ${QueryType.skus} {
        collection {
            key
            value
        }
    }
`;

export const skuCollectionFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuCollectionFragment
  };
};
