import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuMediumImagesFragment = `
    on ${QueryType.skus} {
        media {
            medium {
                src
                alt
                height
                width
                type
            }
        }
    }
`;

export const skuMediumImagesFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuMediumImagesFragment
  };
};
