import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const maincatFragment = `
    on ${QueryType.product} {
        maincat {
            key
            value
        }
    }
`;

export const maincatFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: maincatFragment
  };
};
