import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuPromoMessagingFragment = `
    on ${QueryType.skus} {
        sku_promo_messaging
    }
`;

export const skuPromoMessagingFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuPromoMessagingFragment
  };
};
