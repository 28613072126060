import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const autoReplenishFragment = `
    on ${QueryType.skus} {
        is_replenishable
    }
`;

export const autoReplenishFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: autoReplenishFragment
  };
};
