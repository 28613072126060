import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productCrossSellFragment = `
    on ${QueryType.product} {
        cross_sell {
            sku_id
            sort_key
        }
    }
`;

export const productCrossSellFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productCrossSellFragment
  };
};
