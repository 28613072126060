import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productBenefitStatementFragment = `
    on ${QueryType.product} {
        benefit {
            statement
        }
    }
`;

export const productBenefitStatementFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productBenefitStatementFragment
  };
};
