import { useState } from 'react';
import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { isBrowser } from '@gemini/shared/services/utils/global';
import styles from './devtools.module.scss';

export function DevTools() {
  const slug = isBrowser() ? window.location.pathname : '/';

  const { revisionTags } = useAppContext();

  enum DrawerState {
    OPEN = 'open',
    CLOSED = 'closed'
  }

  const [drawerState, setDrawerState] = useState(DrawerState.CLOSED);

  // @todo add interface for rev tag api response
  const revTagOptions = (revisionTags || []).map((item: any) => {
    return (
      <option key={item.revision_tag} value={item.revision_tag}>
        {item.revision_tag}
      </option>
    );
  });

  const toggleDrawer = () => {
    if (drawerState === DrawerState.CLOSED) {
      setDrawerState(DrawerState.OPEN);
    } else {
      setDrawerState(DrawerState.CLOSED);
    }
  };

  return (
    <div
      className={`${styles['dev-tools']} ${
        styles[`dev-tools--${drawerState}`]
      }`}
    >
      <div className={styles['dev-tools__wrapper']}>
        <button className={styles['dev-tools__toggle']} onClick={toggleDrawer}>
          <span>Preview</span>
        </button>
        <form action="/api/preview" method="get">
          <select
            className={styles['dev-tools__select']}
            id="revTag"
            name="revision"
            defaultValue=""
          >
            <option value="" disabled className="text-center">
              -- Select a Revision Tag --
            </option>
            <option value="none">Default</option>
            {revTagOptions}
          </select>
          <input type="hidden" name="slug" value={slug} />
          <button
            className={styles['dev-tools__button']}
            type="submit"
            onClick={toggleDrawer}
          >
            Preview Content
          </button>
        </form>
      </div>
    </div>
  );
}

export default DevTools;
