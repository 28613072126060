import SkuType, { IImage, IVideo } from './sku';

export enum IItemType {
  PRODUCT = 'product',
  SKU = 'sku'
}

export interface IConfigItem {
  type: IItemType;
  params: {
    id: string;
  };
}

export type DescriptionStatement = {
  content: string | null;
  label: string;
  priority: number;
};

type Usage = {
  content: string;
};

export type IValue = {
  value: string;
};

export interface IVideoPoster {
  src: string;
  height: number;
  width: number;
}

export type IVideoAsset = {
  provider: string;
  src: string;
  poster: IVideoPoster;
  position: number;
  alt?: string;
  type?: string;
};
export interface IProductShadeGroup {
  key: string;
  value: string;
  position?: number;
}

export type ProductType = {
  productId: string;
  attributes?: Record<string, unknown>;
  averageRating?: number;
  badge?: string;
  badgeId?: number;
  benefits?: string;
  crossSell?: string[];
  defaultCategory?: {
    id: string;
    key: string;
    value: string;
  };
  displayName: string;
  description?: string;
  descriptionStatements?: DescriptionStatement[];
  keyIngredient?: string;
  media?: {
    large?: IImage[];
    medium?: IImage[];
    small?: IImage[];
    video?: IVideo[];
    videos?: IVideoAsset[];
  };
  productUrl?: string;
  refillable?: boolean;
  shaded?: boolean;
  shadeGroups?: IProductShadeGroup[];
  shortDescription?: string;
  skus: SkuType[];
  skinTypes?: string;
  subHeader?: string;
  totalReviewCount?: number;
  tags?: string[];
  url?: string;
  usage?: Usage[];
  productBadge?: string | null;
  productBadgeImg?: string | null;
};

export default ProductType;
