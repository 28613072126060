import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productSmallImagesFragment = `
    on ${QueryType.product} {
        media {
            small {
                src
                alt
                type
            }
        }
    }
`;

export const productSmallImagesFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productSmallImagesFragment
  };
};
