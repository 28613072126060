import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const finishFragment = `
    on ${QueryType.skus} {
        finish {
            key
            value
        }
    }
`;

export const finishFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: finishFragment
  };
};
