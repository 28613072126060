import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const subcatFragment = `
    on ${QueryType.product} {
        subcat {
            key
            value
        }
    }
`;

export const subcatFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: subcatFragment
  };
};
