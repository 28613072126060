import type { GetStaticProps, GetStaticPropsContext } from 'next';
import type { IPageData } from '@gemini/shared/services/content/next-mantlecms';
import { ProductType } from '@gemini/shared/services/products/catalog';
import { getPageUrl } from './getPageData';
import { getPageAndProductData } from './pageFetcher';

type StaticPropsParams = {
  shouldUpdateProductInventory: boolean;
  callback?<T extends DefaultPathComponentProps>(
    props: DefaultPathComponentProps
  ): T;
};

export type DefaultPathComponentProps = {
  reqPath: string;
  pageData: IPageData;
  products: ProductType[];
  url: string;
  fallback?: {
    [key: string]: {
      pageData: IPageData;
      products: ProductType[];
    };
  };
  revalidate: number;
};

export const getStaticProps: (a: StaticPropsParams) => GetStaticProps =
  (params: StaticPropsParams) => async (context: GetStaticPropsContext) => {
    const { url: pageDataRequestURL, reqPath, path } = getPageUrl(context);

    const pageAndProductData = await getPageAndProductData(
      pageDataRequestURL,
      params.shouldUpdateProductInventory,
      path as string[]
    );

    return {
      props: {
        reqPath,
        pageData: pageAndProductData.pageData,
        products: pageAndProductData.products,
        url: pageDataRequestURL,
        fallback: {
          [encodeURI(pageDataRequestURL)]: pageAndProductData
        }
      },
      revalidate: Number(process.env.DEFAULT_REVALIDATE_TIME)
    };
  };
