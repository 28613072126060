import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const colorFamilyFragment = `
    on ${QueryType.skus} {
        color_family {
            key
            value
        }
    }
`;

export const colorFamilyFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: colorFamilyFragment
  };
};
