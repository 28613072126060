import { IItemType } from '../types/product';

interface IData {
  items?: {
    params: {
      id: string;
    };
    type: IItemType;
  }[];
  productTags?: string[];
}

export function getIdsFromData(data: IData) {
  const { skuIds, productIds } = (data.items || []).reduce(
    (
      products: { skuIds: string[]; productIds: string[] },
      { type, params }
    ) => {
      if (type === IItemType.PRODUCT) {
        products.productIds.push(params.id);
      } else if (type === IItemType.SKU) {
        products.skuIds.push(params.id);
      }

      return products;
    },
    { skuIds: [], productIds: [] }
  );

  const [tagIds, tagNames] = (data.productTags || []).reduce(
    (tags, tag) => {
      if (Number.isInteger(+tag)) {
        tags[0].push(tag);
      } else {
        tags[1].push(tag);
      }

      return tags;
    },
    [[], []] as string[][]
  );

  return {
    productIds,
    skuIds,
    tagIds,
    tagNames
  };
}
