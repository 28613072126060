import {
  tealiumLink,
  updateDataLayer
} from '@gemini/shared/services/analytics/tealium';

const defaultData = {
  event_name: 'signup',
  event_category: 'signup',
  event_action: null, // signups: email,sms
  event_label: null, // location of email signup
  event_noninteraction: 'false'
};

export function trackEmailSignup(eventData: any = {}) {
  // user data is relevant to the on-site datalayer, so update here
  updateDataLayer(eventData);
  if (eventData.last_source) {
    eventData.event_label = eventData.last_source;
    eventData.event_action = eventData.opt_in_state;
  }

  tealiumLink({ ...defaultData, ...eventData });
}
