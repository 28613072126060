import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const benefitFragment = `
    on ${QueryType.product} {
        benefit {
            benefits {
                key
                value
            }
        }
    }
`;

export const benefitFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: benefitFragment
  };
};
