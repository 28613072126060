import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const styleFragment = `
    on ${QueryType.product} {
        style {
            key
            value
        }
    }
`;

export const styleFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: styleFragment
  };
};
