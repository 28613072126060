import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const descriptionStatementsFragment = `
    on ${QueryType.product} {
        description_statements {
            label
            priority
            content
        }
    }
`;

export const descriptionStatementsFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: descriptionStatementsFragment
  };
};
