import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect, useState } from 'react';
import { processAnalyticsEvents } from '@gemini/shared/services/analytics/events';
import { getPgDataLayer } from '@gemini/shared/services/analytics/fetch-data-layer';
import { initializeOneTrust } from '@gemini/shared/services/analytics/one-trust';
import {
  emitAnalyticsEvent,
  serviceStatuses,
  updateAnalyticsServiceStatus
} from '@gemini/shared/services/analytics/sd-analytics';
import {
  IPostLoadConfig,
  TealiumScript
} from '@gemini/shared/services/analytics/tealium';
import {
  serviceBusEmit,
  serviceBusOn,
  ServiceBusTopics
} from '@gemini/shared/services/communication/next-elc-service-bus';
import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { getBundleUrl } from '@gemini/shared/services/utils/js-repo';

const ANALYTICS_BUNDLE = 'elc-service-analytics@5.x.x';

export interface IAnalyticsServiceProps {
  inEcommContext?: boolean;
}

export const AnalyticsService = (props: IAnalyticsServiceProps) => {
  const { appConfig: config } = useAppContext();
  const router = useRouter();
  const [count, setCount] = useState(0);
  const { inEcommContext } = props;

  useEffect(() => {
    // Don't yell at me, we need this nonsense until we update drupal to not auto-fire a page view on ANALYTICS_LOADED event.
    // Otherwise we get dupes on first page load (non-SPA page load).
    if (count > 0) {
      void emitAnalyticsEvent(ServiceBusTopics.events.ANALYTICS_PAGE_VIEWED);
    }
    setCount(count + 1);
  }, [router.asPath]);

  useEffect(() => {
    const startAnalytics = async () => {
      if (!inEcommContext) {
        void serviceBusEmit(ServiceBusTopics.events.ECOMM_STARTED);
      }

      const diContainer = await (() =>
        import('elc_service_analytics/diContainer'))();
      diContainer.serviceStarter(config).then(async () => {
        serviceBusOn(
          ServiceBusTopics.events.ECOMM_STARTED,
          () => updateAnalyticsServiceStatus(serviceStatuses.STARTED),
          { replay: true }
        );
      });
    };

    startAnalytics().catch(() => {
      updateAnalyticsServiceStatus(serviceStatuses.FAILED);
    });
  }, []);

  const postLoad = useCallback(async ({ dataLayer }: IPostLoadConfig) => {
    processAnalyticsEvents(dataLayer);
    initializeOneTrust();
  }, []);

  return (
    <>
      <TealiumScript getDataLayer={getPgDataLayer} postLoad={postLoad} />
      <Script
        key={ANALYTICS_BUNDLE}
        src={getBundleUrl(ANALYTICS_BUNDLE)}
        strategy="beforeInteractive"
      />
    </>
  );
};
