import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const smartGiftFragment = `
    on ${QueryType.skus} {
        is_giftable
    }
`;

export const smartGiftFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: smartGiftFragment
  };
};
