import { ReactNode, SyntheticEvent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

export type IConfig = {
  brand?: { literal?: string };
} & AnyObject;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAnyObject extends AnyObject {}

export interface IEvent extends SyntheticEvent {
  target: EventTarget & {
    readonly scrollingElement: ReactNode & { readonly scrollTop: number };
  };
}

export enum Animate {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum Direction {
  Up = 'UP',
  Down = 'DOWN',
  Neutral = 'NEUTRAL'
}

declare module 'csstype' {
  //eslint-disable-next-line
  interface Properties {
    // Properties is reserved
    ['--index']?: string;
    [key: string]: string;
  }
}
