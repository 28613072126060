import { tealiumLink } from '@gemini/shared/services/analytics/tealium';

function stripOutMarkup(str: string) {
  return str.replace(/(<([^>]+)>)/gi, '');
}

const defaultData = {
  enh_action: 'add',
  event_name: 'add_to_bag',
  event_category: 'ecommerce',
  event_action: 'add to bag',
  event_label: null, // '<product name> - <product ID>'
  product_id: null, // '<product id>'
  product_shade: null, // '<product shade>'
  product_size: null, // '<product size>'
  product_sku: null // '<sku id>'
};

export function trackAddToCart(eventData: any = {}) {
  if (eventData.product_name && eventData.product_id) {
    eventData.event_label = `${stripOutMarkup(eventData.product_name[0])} - ${
      eventData.product_id[0]
    }`;
  }
  tealiumLink({ ...defaultData, ...eventData });
}
