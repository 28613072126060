import { atom } from 'jotai';
import { noop } from '@gemini/shared/services/utils/global';

export function createInitAtom<T>(initialValue: T, name: string) {
  let resolve = noop;

  const promise = new Promise<void>((_resolve) => {
    resolve = () => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(name, 'set');
      }
      _resolve();
    };
  });

  const baseAtom = atom<T>(initialValue);

  const atomInstance = atom<T, T>(
    (get) => get(baseAtom),
    (get, set, newVal) => {
      if (newVal) {
        resolve();
      }
      set(baseAtom, newVal);
    }
  );

  const awaitPromise = () => promise;

  return {
    awaitPromise,
    promise,
    atomInstance
  };
}
