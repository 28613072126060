import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const textureFragment = `
    on ${QueryType.skus} {
        texture {
            value
        }
    }
`;

export const textureFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: textureFragment
  };
};
