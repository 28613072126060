import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productFinishFragment = `
    on ${QueryType.product} {
        finish {
            key
            value
        }
    }
`;

export const productFinishFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productFinishFragment
  };
};
