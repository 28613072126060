import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productSkinTypeFragment = `
    on ${QueryType.product} {
        skin {
            type {
                key
                value
            }
        }
    }
`;

export const productSkinTypeFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productSkinTypeFragment
  };
};
