import Script from 'next/script';

export interface INextOptimizelyProps {
  id?: number;
}

export const NextOptimizely = ({ id: idOverride }: INextOptimizelyProps) => {
  const id = idOverride
    ? idOverride
    : Number(process.env.NEXT_PUBLIC_OPTIMIZELY_ID);

  if (!id) {
    return null;
  }

  return (
    <>
      <Script
        id="elc-optimizely"
        strategy="beforeInteractive"
        type="text/javascript"
        src={`/js/optimizely-web/${id}.js`}
      />
    </>
  );
};

export default NextOptimizely;
