import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuBadgeImageFragment = `
    on ${QueryType.skus} {
        sku_badge_image
    }
`;

export const skuBadgeImageFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuBadgeImageFragment
  };
};
