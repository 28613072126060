import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skinToneFragment = `
    on ${QueryType.skus} {
        skin {
            tone {
                key
                value
            }
        }
    }
`;

export const skinToneFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skinToneFragment
  };
};
