import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const usageFragment = `
    on ${QueryType.product} {
        usage {
            content
            label
            type
        }
    }
`;

export const usageFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: usageFragment
  };
};
