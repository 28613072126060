import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const occasionFragment = `
    on ${QueryType.skus} {
        occasion {
            key,
            value
        }
    }
`;

export const occasionFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: occasionFragment
  };
};
