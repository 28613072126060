import { ErrorInfo } from 'react';
import {
  // debounceAsync,
  isBrowser,
  isNodeWindow,
  UnknownKeysAndValues
} from '@gemini/shared/services/utils/global';
import { getCountryFromCookies } from '@gemini/shared/utils/browser-storage';

type ErrorLog = {
  error: {
    isTrusted?: boolean;
    stack?: string;
    colno?: number | string;
    lineno?: number | string;
    nodeType?: string;
    message?: string;
  } & UnknownKeysAndValues;
  message: string;
  info: {
    navigator: string;
  } & UnknownKeysAndValues;
};

const sendLogTime: number =
  (process.env.SEND_LOGS_DEBUG_TIME_SECONDS as unknown as number) * 1000;

export class ClientLogger {
  private pendingLogs = new Set<ErrorLog>();

  constructor() {
    // this.sendLog = debounceAsync(this.sendLog, sendLogTime);

    if (typeof window !== 'undefined') {
      window.addEventListener('unhandledrejection', this.onErrorHandler, true);
      window.addEventListener('onrejectionhandled', this.onErrorHandler, true);
      window.addEventListener(
        'onunhandledrejection',
        this.onErrorHandler,
        true
      );
      window.addEventListener('error', this.onErrorHandler, true);
    }
  }

  private getBrowserInfo = () => ({
    navigator: typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent,
    path: isBrowser() ? window.location?.href : undefined,
    location: {
      country: getCountryFromCookies(),
      region: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone
    },
    timestamp: new Date().toUTCString()
  });

  private getNodeData = (node: Element) => {
    let nodeData = {};
    try {
      if (!isNodeWindow(node)) {
        nodeData = {
          nodeType: node.nodeName,
          src: node.getAttribute
            ? node.getAttribute('src') || node.getAttribute('srcset')
            : null
        };
      }
    } catch (error) {
      console.warn(error);
    }

    return nodeData;
  };

  private onErrorHandler = (
    event: Event | PromiseRejectionEvent | ErrorEvent
  ) => {
    const eventError =
      (event as ErrorEvent).error ||
      (event as PromiseRejectionEvent).reason ||
      {};
    const message = eventError.message || (event as ErrorEvent).message;

    const error = this.formatErrorObject(eventError);

    const target = (event.target || {}) as Element;
    const log = {
      error: {
        ...this.getNodeData(target),
        ...error,
        isTrusted: event.isTrusted
      },
      info: this.getBrowserInfo(),
      message: message || 'no information'
    };

    // this.addLogToQueue(log);
    this.logToConsole(log);
  };

  // private addLogToQueue = (log: ErrorLog) => {
  // this.pendingLogs.add(log);
  // this.sendLog();
  // };

  private formatErrorObject = (error: Error | ErrorEvent) => ({
    ...error,
    lineno: (error as ErrorEvent).lineno,
    colno: (error as ErrorEvent).colno,
    stack: (error as Error).stack
  });

  // private sendLog = async () => {
  // const logs = Array.from(this.pendingLogs);
  //   this.pendingLogs.clear();

  //   console.error('Logger error', logs);
  // };

  private logToConsole = (log: ErrorLog) => {
    console.error(log);
  };

  public logError = (error: Error, message?: string, infoError?: ErrorInfo) => {
    const log = {
      error: {
        ...infoError,
        ...this.formatErrorObject(error)
      },
      info: this.getBrowserInfo(),
      message: message || 'no information'
    };

    // this.addLogToQueue(log);
    this.logToConsole(log);
  };
}

let logger: ClientLogger;

export function getLogger(): ClientLogger {
  if (!logger) {
    logger = new ClientLogger();
  }

  return logger;
}
