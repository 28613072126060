import { getAccessToken } from '@gemini/shared/services/access/guest';
import { isBrowser } from '@gemini/shared/services/utils/global';
import { getLogger } from '@gemini/shared/utils/logger';
import {
  mapStardustProductData,
  mapStardustProductIds
} from './mapStardustProductData';

const envPrefix =
  process.env.API_ENV_PRODCAT === 'prod'
    ? ''
    : `${process.env.API_ENV_PRODCAT}.`;

export const prodcatURL = [
  `https://${envPrefix}${process.env.NEXT_PUBLIC_STARDUST_REGION}.sdapi.io`,
  `stardust-prodcat-product-${process.env.API_VERSION_PRODCAT}`,
  `graphql/core/${process.env.API_VERSION_PRODCAT_CORE}`,
  `extension/${process.env.API_VERSION_PRODCAT_EXTENSION}`
].join('/');

export function isTempOOS(status: string) {
  return status === 'Temporarily out of stock';
}

export function isShoppable(status: string) {
  return status === 'Active' || status === 'Free Promo' || isTempOOS(status);
}

const getProductBase = async (query: object) => {
  const origin = `https://www.${process.env.NEXT_PUBLIC_HOST}`;
  const accessToken = getAccessToken(`${process.env.NEXT_PUBLIC_BUID}`, origin);

  const headers: {
    [key: string]: any;
  } = new Headers({
    'Content-Type': 'application/json',
    'business-unit': `${process.env.NEXT_PUBLIC_BUID}`,
    clientid: `${process.env.NEXT_PUBLIC_CLIENT_ID}`,
    authorizationtoken: accessToken,
    Origin: origin
  });

  if (!isBrowser()) {
    headers.append(
      'cookie',
      `${headers.cookie}; ${process.env.NEXT_COOKIE_BYPASS_WAF}`
    );
  }

  const res = await fetch(prodcatURL, {
    method: 'POST',
    headers,
    body: JSON.stringify(query)
  });

  const jsonResponse = await res.json();

  if (!res.ok) {
    throw new Error(`Error at getProductBase: ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
};

interface IParams {
  query: string;
  variables: {
    productIds: string[];
    skuIds: string[];
  };
}

export const getStardustProductData = async (params: IParams) => {
  try {
    const variables: Partial<IParams['variables']> = {};

    if (params.variables.skuIds.length > 0) {
      variables.skuIds = params.variables.skuIds;
    }
    if (params.variables.productIds.length > 0) {
      variables.productIds = params.variables.productIds;
    }

    const jsonResponse = await getProductBase({
      query: params.query,
      variables
    });

    const productsMapped = mapStardustProductData({
      productData: jsonResponse
    });

    return productsMapped;
  } catch (error) {
    getLogger<true>().logError(error as Error);

    return [];
  }
};

export const getStardustProductDataByCategoryId = async (body: object) => {
  try {
    const jsonResponse = await getProductBase(body);
    const productIds = mapStardustProductIds(jsonResponse || []);

    return productIds;
  } catch (error) {
    getLogger<true>().logError(error as Error);

    return [];
  }
};

export const getStardustProductDataByTagName = async (body: object) => {
  try {
    const jsonResponse = await getProductBase(body);
    const productIds = mapStardustProductIds(jsonResponse || []);

    return productIds;
  } catch (error) {
    getLogger<true>().logError(error as Error);

    return [];
  }
};
