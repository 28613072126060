import {
  isBrowser,
  isLocalDev,
  isProd
} from '@gemini/shared/services/utils/global';
import { getCookie, setCookie } from '@gemini/shared/utils/browser-storage';

export const CSRF_TOKEN = 'csrftoken';
export const CSRF_GET_TOKEN_METHOD = 'csrf.getToken';

const serverHost = `${process.env.NEXT_PUBLIC_PERLGEM_SUBDOMAIN}.${process.env.NEXT_PUBLIC_HOST}`;

const fetchCSRFToken = async () => {
  const request = [];

  request.push({
    method: CSRF_GET_TOKEN_METHOD,
    params: [{}]
  });

  const url = `/api/rpc/csrfToken?dbgmethod=${CSRF_GET_TOKEN_METHOD}`;
  const protocol = isLocalDev() ? 'http' : 'https';
  const origin = `${protocol}://${
    isBrowser() ? window.location.host : serverHost
  }`;
  const rpcPath = isProd() || !isBrowser() ? '/rpc/jsonrpc.tmpl' : url;
  const requestUrl: RequestInfo = `${origin}${rpcPath}`;

  const isInternalAPIRoute = rpcPath.startsWith('/api/');

  const stringifiedData = JSON.stringify(request);

  const body = isInternalAPIRoute
    ? stringifiedData
    : new URLSearchParams({ JSONRPC: stringifiedData });

  const headers: HeadersInit = {
    'Content-Type': isInternalAPIRoute
      ? 'application/json'
      : 'application/x-www-form-urlencoded; charset=UTF-8'
  };

  if (!isBrowser()) {
    headers.cookie = `${process.env.NEXT_COOKIE_BYPASS_WAF}`;
  }

  const res = await fetch(requestUrl, {
    headers,
    body,
    method: 'POST'
  });

  const jsonResponse = await res.json();

  const result: string =
    jsonResponse[0].result?.value?.token || jsonResponse[0].result?.data?.token;

  setCookie(CSRF_TOKEN, result);

  return result;
};

export const getCSRFToken = async (): Promise<string> => {
  let token = getCookie(CSRF_TOKEN);

  if (!token || token === 'undefined') {
    token = await fetchCSRFToken();
  }

  return token;
};
