import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productLargeImagesFragment = `
    on ${QueryType.product} {
        media {
            large {
                src
                alt
                type
            }
        }
    }
`;

export const productLargeImagesFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productLargeImagesFragment
  };
};
