import { Features, IFeatures } from './Features';
import { defaultFragments, QueryType } from './features/Default';
import { brushShapesFragments } from './features/filters/BrushShapes';
import { brushTypesFragments } from './features/filters/BrushTypes';
import { finishFragments } from './features/filters/Finish';
import { finishOptionsFragments } from './features/filters/FinishOptions';
import { fragranceNotesFragments } from './features/filters/FragranceNotes';
import { freeFromFragments } from './features/filters/FreeFrom';
import { keyIngredientsFragments } from './features/filters/KeyIngredient';
import { keyIngredientsCollectionFragments } from './features/filters/KeyIngredientsCollection';
import { maincatFragments } from './features/filters/Maincat';
import { moodFragments } from './features/filters/Mood';
import { occasionFragments } from './features/filters/Occasion';
import { occasionProductFragments } from './features/filters/OccasionProduct';
import { productFinishFragments } from './features/filters/ProductFinish';
import { productSkinTypeFragments } from './features/filters/ProductSkinType';
import { skinConcernFragments } from './features/filters/SkinConcern';
import { skinGoalFragments } from './features/filters/SkinGoal';
import { skinToneFragments } from './features/filters/SkinTone';
import { skinTypeFragments } from './features/filters/SkinType';
import { skuMaincatFragments } from './features/filters/SkuMaincat';
import { styleFragments } from './features/filters/Style';
import { subcatFragments } from './features/filters/Subcat';
import { textureFragments } from './features/filters/Texture';
import { undertoneFragments } from './features/filters/Undertone';
import { usageOptionsFragments } from './features/filters/UsageOptions';
import { formFragments } from './features/Form';
import { productLargeImagesFragments } from './features/images/ProductLargeImages';
import { productMediumImagesFragments } from './features/images/ProductMediumImages';
import { productSmallImagesFragments } from './features/images/ProductSmallImages';
import { skuLargeImagesFragments } from './features/images/SkuLargeImages';
import { skuMediumImagesFragments } from './features/images/SkuMediumImages';
import { skuModelImagesFragments } from './features/images/SkuModelImages';
import { skuResponsiveImagesFragments } from './features/images/SkuResponsiveImages';
import { skuSmallImagesFragments } from './features/images/SkuSmallImages';
import { skuSmooshImagesFragments } from './features/images/SkuSmooshImages';
import { mandatoryFragments } from './features/Mandatory';
import { discountsFragments } from './features/priceDisplay/Discounts';
import { taxExcludedPriceFragments } from './features/priceDisplay/TaxExcludedPrice';
import { productCollectionFragments } from './features/ProductCollection';
import { autoReplenishFragments } from './features/productDisplay/AutoReplenish';
import { benefitFragments } from './features/productDisplay/Benefit';
import { brandFragments } from './features/productDisplay/Brand';
import { colorFamilyFragments } from './features/productDisplay/ColorFamily';
import { descriptionStatementsFragments } from './features/productDisplay/descriptionStatements';
import { expirationDateFragments } from './features/productDisplay/ExpirationDate';
import { formulatedWithoutFragments } from './features/productDisplay/FormulatedWithout';
import { formulatedWithoutProductFragments } from './features/productDisplay/FormulatedWithoutProduct';
import { fragranceFamilyFragments } from './features/productDisplay/FragranceFamily';
import { perlgemFragments } from './features/productDisplay/Perlgem';
import { preorderFragments } from './features/productDisplay/Preorder';
import { productBadgeImageFragments } from './features/productDisplay/ProductBadgeImage';
import { productBenefitStatementFragments } from './features/productDisplay/ProductBenefitStatement';
import { productCoverageFragments } from './features/productDisplay/ProductCoverage';
import { productReviewsFragments } from './features/productDisplay/ProductReviews';
import { productVideosFragments } from './features/productDisplay/ProductVideos';
import { recipientFragments } from './features/productDisplay/Recipient';
import { reviewsNumberOfQuestionsFragments } from './features/productDisplay/ReviewsNumberOfQuestions';
import { shadeGroupsFragments } from './features/productDisplay/ShadeGroups';
import { shadeImageFragments } from './features/productDisplay/ShadeImage';
import { sizeDescriptionFragments } from './features/productDisplay/SizeDescription';
import { skuBadgeImageFragments } from './features/productDisplay/SkuBadgeImage';
import { skuBenefitStatementFragments } from './features/productDisplay/SkuBenefitStatement';
import { skuPromoMessagingFragments } from './features/productDisplay/SkuPromoMessaging';
import { skuTagsFragments } from './features/productDisplay/SkuTags';
import { skuUrlFragments } from './features/productDisplay/SkuUrl';
import { subDisplayNameFragments } from './features/productDisplay/SubDisplayName';
import { subHeaderFragments } from './features/productDisplay/SubHeader';
import { usageFragments } from './features/productDisplay/Usage';
import { skuCollectionFragments } from './features/SkuCollection';
import { engravingFragments } from './features/tools/Engraving';
import { locationFragments } from './features/tools/Location';
import { productCrossSellFragments } from './features/tools/ProductCrossSell';
import { smartGiftFragments } from './features/tools/SmartGift';
import { vtoCrossSellFragments } from './features/tools/VtoCrossSell';
import { vtoFoundationFragments } from './features/tools/VtoFoundation';
import { vtoMakeupFragments } from './features/tools/VtoMakeup';

export type IFeaturesFragments = {
  [key in Features | 'default' | 'mandatory']?: IQueryTypeFragments;
};

export type IQueryTypeFragments = {
  [key in QueryType]?: string;
};

export interface IConfig {
  features: Partial<IFeatures>;
}

const getDiscountTypes = (features?: Partial<IFeatures>) => {
  return (features && features.discounts && features.discounts.types) || [];
};

const isExcludeTaxEnabled = (features?: Partial<IFeatures>) => {
  return features && features.excludeTax && features.excludeTax.enabled;
};

export const getAllFragments = ({ features }: IConfig): IFeaturesFragments => {
  const discountTypes = getDiscountTypes(features);

  return {
    mandatory: mandatoryFragments(),
    default: defaultFragments(),
    productLargeImages: productLargeImagesFragments(),
    productMediumImages: productMediumImagesFragments(),
    productSmallImages: productSmallImagesFragments(),
    shadeGroups: shadeGroupsFragments(),
    skuLargeImages: skuLargeImagesFragments(),
    skuMediumImages: skuMediumImagesFragments(),
    skuSmallImages: skuSmallImagesFragments(),
    skuSmooshImages: skuSmooshImagesFragments(),
    skuModelImages: skuModelImagesFragments(),
    autoReplenish: autoReplenishFragments(),
    benefit: benefitFragments(),
    brand: brandFragments(),
    colorFamily: colorFamilyFragments(),
    descriptionStatements: descriptionStatementsFragments(),
    discounts: discountTypes.length
      ? discountsFragments(discountTypes, isExcludeTaxEnabled(features))
      : undefined,
    engraving: engravingFragments(),
    excludeTax: taxExcludedPriceFragments(),
    expirationDate: expirationDateFragments(),
    finishOptions: finishOptionsFragments(),
    form: formFragments(),
    freeFrom: freeFromFragments(),
    fragranceFamily: fragranceFamilyFragments(),
    fragranceNotes: fragranceNotesFragments(),
    keyIngredients: keyIngredientsFragments(),
    keyIngredientsCollection: keyIngredientsCollectionFragments(),
    location: locationFragments(),
    maincat: maincatFragments(),
    mood: moodFragments(),
    occasion: occasionFragments(),
    occasionProduct: occasionProductFragments(),
    perlgem: perlgemFragments(),
    preOrder: preorderFragments(),
    productBadgeImage: productBadgeImageFragments(),
    productCollection: productCollectionFragments(),
    productCoverage: productCoverageFragments(),
    productCrossSell: productCrossSellFragments(),
    productFinish: productFinishFragments(),
    productReview: productReviewsFragments(),
    productSkinType: productSkinTypeFragments(),
    productBenefitStatement: productBenefitStatementFragments(),
    recipient: recipientFragments(),
    reviewsNumberOfQuestions: reviewsNumberOfQuestionsFragments(),
    sizeDescription: sizeDescriptionFragments(),
    skuBadgeImage: skuBadgeImageFragments(),
    skinConcern: skinConcernFragments(),
    skinGoal: skinGoalFragments(),
    skinTone: skinToneFragments(),
    skinType: skinTypeFragments(),
    skuCollection: skuCollectionFragments(),
    skuUrl: skuUrlFragments(),
    smartGift: smartGiftFragments(),
    style: styleFragments(),
    subcat: subcatFragments(),
    subDisplayName: subDisplayNameFragments(),
    subHeader: subHeaderFragments(),
    texture: textureFragments(),
    undertone: undertoneFragments(),
    usage: usageFragments(),
    vtoFoundation: vtoFoundationFragments(),
    vtoMakeup: vtoMakeupFragments(),
    vtoCrossSell: vtoCrossSellFragments(),
    shadeImage: shadeImageFragments(),
    finish: finishFragments(),
    productVideos: productVideosFragments(),
    usageOptions: usageOptionsFragments(),
    formulatedWithout: formulatedWithoutFragments(),
    formulatedWithoutProduct: formulatedWithoutProductFragments(),
    skuTags: skuTagsFragments(),
    skuMaincat: skuMaincatFragments(),
    skuPromoMessaging: skuPromoMessagingFragments(),
    skuResponsiveImages: skuResponsiveImagesFragments(),
    skuBenefitStatement: skuBenefitStatementFragments(),
    brushTypes: brushTypesFragments(),
    brushShapes: brushShapesFragments()
  };
};

export const getEnabledFeatures = ({ features }: IConfig) => {
  const enabledFeatures: string[] = [];

  if (features) {
    Object.keys(features).forEach((featureKey) => {
      const feature = features && features[featureKey as Features];
      if (feature && feature.enabled) {
        enabledFeatures.push(featureKey);
      }
    });
  }

  return enabledFeatures;
};

export const getEnabledFragments = (config: IConfig) => {
  const { features } = config;

  const availableFragments: { [key in string]?: string } = {
    product_default: defaultFragments()[QueryType.product],
    product__skus_default: defaultFragments()[QueryType.skus],
    product__collection: mandatoryFragments()[QueryType.collection]
  };

  const enabledFeatures = getEnabledFeatures(config);
  const fragments = getAllFragments(config);

  Object.keys(fragments)
    .filter((key) => enabledFeatures.includes(key))
    .forEach((key) => {
      if (fragments[key as Features]) {
        const featureFragments = fragments[
          key as Features
        ] as IQueryTypeFragments;

        Object.keys(featureFragments).forEach((type) => {
          availableFragments[`${type as QueryType}_${key}`] =
            featureFragments[type as QueryType] || '';
        });
      }
    });

  return availableFragments as IQueryTypeFragments;
};

export const getQueryFragments = (config: IConfig) => {
  const availableFragments: IQueryTypeFragments = {
    product: '...product_default ',
    product__skus: '...product__skus_default ',
    product_collection: '...product__collection '
  };

  const enabledFeatures = getEnabledFeatures(config);
  const fragments = getAllFragments(config);

  enabledFeatures.forEach((feature) => {
    const featureFragments = fragments[feature as Features] || [];

    Object.keys(featureFragments).forEach((type) => {
      availableFragments[type as QueryType] = (
        availableFragments[type as QueryType] || ''
      ).concat(`...${type}_${feature} `);
    });
  });

  return availableFragments as IQueryTypeFragments;
};
