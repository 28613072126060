export const JS_REPO_ORIGIN = `https://${process.env.NEXT_PUBLIC_MANTLE_CMS_SUBDOMAIN}.${process.env.NEXT_PUBLIC_HOST}`;

const JS_REPO_ENV =
  process.env.NEXT_PUBLIC_JS_REPO_ENV !== 'prod'
    ? `${process.env.NEXT_PUBLIC_JS_REPO_ENV}.js-repo`
    : 'js-repo';
export const JS_REPO_HREF = [JS_REPO_ORIGIN, JS_REPO_ENV].join('/');
const isUrl = (value: string) => value.indexOf('http') === 0;

const JS_REPO_ENV_OVERRIDES =
  process.env.NEXT_PUBLIC_JS_REPO_ENV_OVERRIDES || '{}';
const jsRepoEnvOverrides = JSON.parse(JS_REPO_ENV_OVERRIDES) as Record<
  string,
  string
>;

export const [localBundles, remoteBundles] = Object.entries(
  jsRepoEnvOverrides
).reduce(
  (services: Record<string, string>[], [serviceName, path]) => {
    const servicesListIndex = isUrl(path) ? 0 : 1;
    services[servicesListIndex][serviceName] = path;

    return services;
  },
  [{}, {}]
);

function getLocalPath(url: string, serviceName: string) {
  if (url[url.length - 1] === '/') {
    url.substring(0, url.length - 1);
  }

  return `${url}/fe-${serviceName}/build/bundle.js`;
}

function getRemotePath(lib: string, jsRepoEnvOverride: string) {
  return `${JS_REPO_ORIGIN}/${jsRepoEnvOverride}/${lib}/bundle.js`;
}

export function getBundleUrl(lib: string) {
  let url = `${JS_REPO_HREF}/${lib}/bundle.js`;
  const serviceName = lib.split('@')[0];

  if (localBundles[serviceName]) {
    url = getLocalPath(localBundles[serviceName], serviceName);
  } else if (remoteBundles[serviceName]) {
    url = getRemotePath(lib, remoteBundles[serviceName]);
  }

  return url;
}
