import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const formulatedWithoutFragment = `
    on ${QueryType.product} {
        iln_formulated_without
    }
`;

export const formulatedWithoutProductFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: formulatedWithoutFragment
  };
};
