// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import type {
  IEcommApp,
  IServiceRegistry,
  IViewManager
} from '@gemini/shared/services/content/next-elc-ecomm';
import { isBrowser } from '@gemini/shared/services/utils/global';
import type { IConfig } from './interfaces';

export function mergeConfigs(
  componentConfig: IConfig = {},
  globalConfig: IConfig = {}
): IConfig {
  return { ...globalConfig, ...componentConfig };
}

export function findCommonUrlValues(array1: string[], array2: string[]) {
  return Array.from(new Set(array1.filter((value) => array2.includes(value))));
}

interface IGlobalCommon {
  Buffer: Buffer;
  innerWidth: number;
  innerHeight: number;
  site: {
    [key: string]: Record<string, unknown>;
  };
  ViewsManager: IViewManager;
  EcommApp: IEcommApp;
  IntersectionObserver: any;
  ServiceRegistry: IServiceRegistry;
  location: Location;
  navigator: Navigator;
  serviceQueries: Record<string, unknown>;
  pwr: any;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    useCapture?: boolean
  ): void;
  dispatchEvent(evt: Event): boolean;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    useCapture?: boolean
  ): void;
  cookieStore: Record<
    string,
    (value: string) => Promise<Record<string, string>>
  >;
  OneTrust: any;
  onOneTrustLoaded: any;
  scrollTo(options: {
    left: number;
    top: number;
    behavior: 'smooth' | 'auto' | 'instant';
  }): void;
  pageYOffset: number;
}

declare global {
  // Augment Node.js `global`
  /* eslint-disable-next-line */
  namespace NodeJS {
    /* eslint-disable-next-line */
    interface Global extends IGlobalCommon {}
  }
  // Augment Browser `window`
  /* eslint-disable-next-line */
  interface Window extends IGlobalCommon {}
}

export const root: NodeJS.Global | Window = isBrowser()
  ? window
  : (global as unknown as NodeJS.Global);
