import type { NextApiRequest, NextApiResponse } from 'next';
import { IPageData } from '@gemini/shared/services/content/next-mantlecms';
import { getDefaultRetryOptions, retry } from '@gemini/shared/utils/retry';
import { getPageDataHandler } from './handler';
import { mantleCMSFetch } from './mantleCMSFetch';

export type NodeData = {
  pageData: IPageData;
};

export async function getPageData(url: string): Promise<IPageData> {
  const pageData = await mantleCMSFetch(url);

  return pageData;
}

export const getNodeData = async (
  pageDataRequestURL: string
): Promise<NodeData> => {
  const nodeData = await retry(
    () => getPageData(pageDataRequestURL),
    getDefaultRetryOptions('getNodeData')
  )();

  const pageDataErr = nodeData?.error;
  if (pageDataErr) {
    throw new Error(
      `${pageDataErr.code} for ${pageDataRequestURL}: ${pageDataErr.message}`
    );
  }

  return {
    pageData: nodeData
  };
};

export const getNode = async (req: NextApiRequest, res: NextApiResponse) => {
  const origin = process.env.MANTLE_CMS_URL as string;
  const url = new URL(`${origin}/api/content/v2/data?nodes=${req.body}`);
  await getPageDataHandler<NodeData>(req, res, () =>
    getNodeData(url.toString())
  );
};

export default getNode;
