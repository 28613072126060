import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuTagsFragment = `
    on ${QueryType.skus} {
        tags {
            items {
                id
                value
                key
            }
        }
    }
`;

export const skuTagsFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuTagsFragment
  };
};
