import { IDiscountTypes } from '../../Discount';
import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const excludeTaxFragment = `
    exclude_tax {
        original_price
        price
        price_per_unit
    }
`;

export const discountsFragment = (hasExcludeTax: boolean) => `
    total
    items {
        discount_percent
        discount_applied {
            type
            value
        }
        offer_code
        offer_messaging
        prices {
            currency
            ${hasExcludeTax ? excludeTaxFragment : ''}
            include_tax {
                original_price
                price
                price_per_unit
            }
        }
    }
`;

export const discountsFragments = (
  discountTypes: IDiscountTypes[] = [],
  hasExcludeTax = false
): IQueryTypeFragments => {
  return {
    [QueryType.skus]: `
            on ${QueryType.skus} {
                discounts${`(filter: "{ \\"offers\\": { \\"promotions\\": [${discountTypes.map(
                  (discountType) => `\\"${discountType}\\"`
                )}]} }")`} {
                    ${discountsFragment(hasExcludeTax)}
                }
            }
        `
  };
};
