/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ProductType } from '@gemini/shared/services/products/catalog';
import {
  jsonRPCFetch,
  jsonRPCFetchResponse
} from '@gemini/shared/services/utils/pg-jsonrpc';
import { deriveInventoryLabel } from './inventoryLabel';
import { mapPerlgemProductData } from './mapPerlgemProductData';

interface IGetPerlgemInventory {
  query?: string;
  variables: {
    productIds: string[];
  };
}

type Products = {
  PROD_BASE_ID: number;
  skus: { SKU_BASE_ID: string; INVENTORY_STATUS: number }[];
};

interface IPerlgemInventoryRes {
  products: (Products | null)[];
}

export const getPerlgemInventory = async (params: IGetPerlgemInventory) => {
  const { variables } = params;
  const request: any = [];

  request.push({
    method: 'prodcat',
    params: [
      {
        products: variables.productIds || [],
        product_fields: ['PROD_BASE_ID', 'skus'],
        sku_fields: ['INVENTORY_STATUS', 'SKU_BASE_ID'],
        bypass_datalayer: 1
      }
    ]
  });

  const res = await jsonRPCFetch({
    url: '',
    request
  });
  const response: IPerlgemInventoryRes = await jsonRPCFetchResponse(
    res as Response
  );

  const products = (response?.products.filter(Boolean) as Products[]) || [];

  const inventoryMap = products.map((product) => {
    const product_id = `${product.PROD_BASE_ID}`;

    const skus = product.skus.map((sku) => ({
      sku_id: `${sku.SKU_BASE_ID}`,
      inventory_status: deriveInventoryLabel(sku.INVENTORY_STATUS)
    }));

    return {
      product_id,
      skus
    };
  });

  return inventoryMap;
};

interface IGetPerlgemProducts {
  queryKey?: string;
  variables: {
    categoryIds?: string[];
    productIds?: string[];
    skuIds?: string[];
  };
}

export const getPerlgemProducts = async (
  params: IGetPerlgemProducts
): Promise<ProductType[]> => {
  const {
    variables: { categoryIds, productIds, skuIds },
    queryKey
  } = params;
  const request = [];

  request.push({
    method: 'prodcat.querykey',
    params: [
      {
        categories: categoryIds,
        products: productIds,
        skus: skuIds,
        query_key: queryKey
      }
    ]
  });

  const res = await jsonRPCFetch({
    url: '',
    request,
    useCSRFTokenParam: true
  });

  const response = await jsonRPCFetchResponse(res as Response);
  const productsResponse = [];

  if (!response) {
    return [];
  }

  if (response.products && response.products.length) {
    productsResponse.push(...response.products);
  }

  if (response.categories && response.categories.length) {
    const extractedProducts = response.categories.reduce(
      (products: any, category: any) => {
        if (category.products && category.products.length) {
          products.push(...category.products);
        }

        return products;
      },
      []
    );

    productsResponse.push(...extractedProducts);
  }

  return mapPerlgemProductData(productsResponse || []);
};
