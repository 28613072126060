import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuMaincatFragment = `
    on ${QueryType.skus} {
        maincat {
            key
            value
        }
    }
`;

export const skuMaincatFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuMaincatFragment
  };
};
