import {
  formattedResponseError,
  isBrowser,
  isLocalDev,
  isProd
} from '@gemini/shared/services/utils/global';
import { getLogger } from '@gemini/shared/utils/logger';
import { getCSRFToken } from './csrfToken';

const serverHost = `${process.env.NEXT_PUBLIC_PERLGEM_SUBDOMAIN}.${process.env.NEXT_PUBLIC_HOST}`;

// eslint-disable-next-line complexity
export const jsonRPCFetch = async (params: {
  url: string;
  request: any;
  userAgent?: string;
  headers?: {
    [key: string]: any;
  };
  useCSRFTokenParam?: boolean;
  useCSRFTokenHeader?: boolean;
  dbgmethod?: string;
}) => {
  try {
    const {
      url,
      request,
      userAgent,
      headers = {},
      useCSRFTokenHeader = false,
      useCSRFTokenParam = false,
      dbgmethod
    } = params;
    const protocol = isLocalDev() ? 'http' : 'https';
    const origin = `${protocol}://${
      isBrowser() ? window.location.host : serverHost
    }`;
    const rpcPath =
      isProd() || !isBrowser()
        ? `/rpc/jsonrpc.tmpl${dbgmethod ? `?dbgmethod=${dbgmethod}` : ''}`
        : url;
    const isInternalAPIRoute = rpcPath.startsWith('/api/');
    const requestUrl: RequestInfo = `${origin}${rpcPath}`;

    let token = '';
    const data = request;

    if (useCSRFTokenParam || useCSRFTokenHeader) {
      token = await getCSRFToken();
    }

    if (useCSRFTokenParam && Array.isArray(data)) {
      data[0].params[0] = {
        ...data[0].params[0],
        _TOKEN: token
      };
    } else if (useCSRFTokenParam) {
      data.params[0] = {
        ...data.params[0],
        _TOKEN: token
      };
    }

    const isAlreadyFormEncoded = Object.keys(data).includes('JSONRPC');
    const stringifiedData = isAlreadyFormEncoded
      ? data.JSONRPC
      : JSON.stringify(data);
    const body = isInternalAPIRoute
      ? stringifiedData
      : new URLSearchParams({ JSONRPC: stringifiedData });

    if (!isBrowser()) {
      headers.cookie = (headers.cookie ? `${headers.cookie} ;` : '').concat(
        process.env.NEXT_COOKIE_BYPASS_WAF || ''
      );
    }

    headers['content-type'] = isInternalAPIRoute
      ? 'application/json'
      : 'application/x-www-form-urlencoded; charset=UTF-8';

    const headersInit: HeadersInit = {
      ...headers,
      ...(useCSRFTokenHeader ? { 'x-csrf-token': token } : {}),
      ...(isBrowser()
        ? {}
        : {
            ['User-Agent']: userAgent || 'SSR'
          })
    };

    const init: RequestInit = {
      headers: headersInit,
      body,
      method: 'POST',
      credentials: 'include'
    };

    const res = await fetch(requestUrl, init);

    if (res.ok) {
      return res;
    }

    const error = new Error('Network response not ok.');
    formattedResponseError(requestUrl, res, error);
  } catch (error) {
    getLogger().logError(error as Error);

    return error;
  }
};
