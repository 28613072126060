import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productCoverageFragment = `
    on ${QueryType.product} {
        coverage {
            key
            value
        }
    }
`;

export const productCoverageFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productCoverageFragment
  };
};
