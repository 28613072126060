import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuUrlFragment = `
    on ${QueryType.skus} {
        sku_url
    }
`;

export const skuUrlFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuUrlFragment
  };
};
