import {
  isBrowser,
  returnFirstIfTrue
} from '@gemini/shared/services/utils/global';
import { ClientLogger, getLogger as getClientLogger } from './ClientLogger';
import { getLogger as getServerLogger, ServerLogger } from './ServerLogger';

export function getLogger<T extends boolean = false>(): returnFirstIfTrue<
  T,
  ServerLogger,
  ClientLogger
> {
  const logger = isBrowser() ? getClientLogger() : getServerLogger();

  return logger as returnFirstIfTrue<T, ServerLogger, ClientLogger>;
}
