import { emitAnalyticsEvent } from '@gemini/shared/services/analytics/sd-analytics';

const QUICK_VIEW_TRIGGERED = 'quick.view.triggered';

export async function trackProductQuickView(
  product: any,
  positionIndex: number
) {
  emitAnalyticsEvent(QUICK_VIEW_TRIGGERED, {
    positionIndex: positionIndex,
    product: product
  });
}
