import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const fragranceFamilyFragment = `
    on ${QueryType.product} {
        fragrance_family {
            key
            value
        }
    }
`;

export const fragranceFamilyFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: fragranceFamilyFragment
  };
};
