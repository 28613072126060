import {
  jsonRPCFetch,
  jsonRPCFetchResponse
} from '@gemini/shared/services/utils/pg-jsonrpc';

export const SUBSCRIBE_PRODUCT_METHOD = 'form.get';

export interface IQueryParams {
  _SUBMIT: string;
  EVENT_NAME: string;
  REQUEST_TYPE: string;
  EMAIL_ADDRESS: string;
  SKU_BASE_ID: string;
}

export const subscribeProduct = async (
  data: Record<string, unknown>
): Promise<any> => {
  const request: any = [];

  const { skuId, email } = data;

  const params = [
    {
      _SUBMIT: 'bis_notification',
      EVENT_NAME: 'BIS',
      REQUEST_TYPE: 'BIS',
      EMAIL_ADDRESS: email,
      SKU_BASE_ID: skuId
    }
  ];

  const url = `/rpc/jsonrpc.tmpl?dbgmethod=${SUBSCRIBE_PRODUCT_METHOD}`;

  request.push({
    method: SUBSCRIBE_PRODUCT_METHOD,
    params
  });

  const res = await jsonRPCFetch({
    url,
    request,
    useCSRFTokenParam: true,
    useCSRFTokenHeader: true
  });

  const response: any = await jsonRPCFetchResponse(res as Response);

  return response;
};
