import { IQueryTypeFragments } from '../QueryFragments';
import { QueryType } from './Default';

const productCollectionFragment = `
    on ${QueryType.product} {
        collection {
            key
            value
        }
    }
`;

export const productCollectionFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productCollectionFragment
  };
};
