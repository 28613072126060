import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const keyIngredientsFragment = `
    on ${QueryType.product} {
        key_ingredient
    }
`;

export const keyIngredientsFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: keyIngredientsFragment
  };
};
