import { updateDataLayer } from '@gemini/shared/services/analytics/data-layer';
import { processAnalyticsEvents } from '@gemini/shared/services/analytics/events';

export async function jsonRPCFetchResponse(res: Response) {
  if (!res.ok || res instanceof Error) {
    return null;
  }

  const jsonResponse = await res.json();

  const result = jsonResponse[0].result.value || jsonResponse[0].result.data;
  result.error = jsonResponse[0].error;

  if (result && result.dataLayer) {
    updateDataLayer(result.dataLayer);
    processAnalyticsEvents(result.dataLayer);
  }

  return result;
}
