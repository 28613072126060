import { WithEcommRenderer } from './WithEcommRenderer';
import ServiceViewRenderer, { ServiceViewType } from './ServiceViewRenderer';

export function ServiceView({ data, metadata }: ServiceViewType) {
  const { serviceName, serviceViewName } = metadata;

  // @todo GEMINI-691 remove discovery-gnav exception once API has the servicename
  if (!serviceName && serviceViewName !== 'discovery-gnav') {
    return null;
  }

  const Component = ServiceViewRenderer;

  // @todo GEMINI-692 remove this once the Drupal bug is fixed
  if (serviceViewName === 'search-gnav-input-field') {
    data.config.componentElementsOrderConfig = {
      productBrief: {
        'main-content': {
          mobileWrapper: {
            weight: 1,
            elements: {
              carousel: {
                weight: 1
              }
            }
          },
          desktopWrapper: {
            weight: 2,
            elements: {
              productImage: {
                weight: 2
              }
            }
          },
          displayName: {
            weight: 3
          },
          price: {
            weight: 4
          }
        }
      }
    };
  }

  return (
    <WithEcommRenderer>
      <Component data={data} metadata={metadata} />
    </WithEcommRenderer>
  );
}

export default ServiceView;
