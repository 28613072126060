import { mantleCMSFetch } from './mantleCMSFetch';

export function getTranslationRequestURL(path: string) {
  // EXAMPLE REQUEST URL FROM ELC-SERVICE-TRANSLATIONS
  // https://e.genie2.na.us.cms.elcdev.net/elc_api/translations/elc_account:*&elc_account_errors:*&elc_appt_booking:*&elc_cart:*&elc_cart_errors:*&elc_checkout:*&elc_common:*&elc_communications:*&elc_compliance:*&elc_delivery_tout:*&elc_errors:*&elc_general:*&elc_loyalty:*&elc_offers:*&elc_orders:*&elc_product:*&elc_product_attributes:*&elc_search:*&elc_stores:*&elc_user:*&elc_vto:*/buid:2-gn-us-en-ecommv1

  const reqPath = path;
  const prefix = '/elc_api/translations/';
  const url = process.env.MANTLE_CMS_URL + prefix + reqPath;

  return url;
}

export async function getTranslationsData(url: string) {
  const translations = await mantleCMSFetch(url);

  return translations;
}
