import { ProductType } from '../types/product';
import { SkuType } from '../types/sku';

export const sortProductsByInventoryStatus = (
  products: ProductType[],
  inventoryStatusSorting: string[]
) => {
  const sortStatus = inventoryStatusSorting.map((item: string) =>
    item.toLowerCase()
  );

  const getAllSkusInventoryStatus = (product: ProductType) => {
    const statusData = {} as any;

    sortStatus.forEach((item: string, i: number) => {
      statusData[item] = {
        order: i + 1,
        status: product.skus.every((sku: SkuType) => {
          return sku.inventoryStatus.toLowerCase() === item;
        })
      };
      statusData.productId = product.productId;
    });

    return statusData;
  };

  const skusInventoryStatus = products.map((item: ProductType) => {
    return getAllSkusInventoryStatus(item);
  });

  const checkDefaultSkuInventoryStatus = (
    product: ProductType,
    sortBy: string
  ) => {
    return product.skus.some(
      (item: SkuType) =>
        item.isDefaultSku && item.inventoryStatus.toLowerCase() === sortBy
    );
  };

  const setProductsOrder = (product: ProductType) => {
    const currentProduct = skusInventoryStatus.find(
      (item: any) => item.productId === product.productId
    );

    let result = null;

    sortStatus.forEach((item: string) => {
      if (
        currentProduct[item].status === true ||
        checkDefaultSkuInventoryStatus(product, item)
      ) {
        result = {
          order: sortStatus.indexOf(item) + 1,
          ...product
        };
      } else {
        result = {
          order: 0,
          ...product
        };
      }
    });

    return result;
  };

  const sortProductsByOrder = (productsData: ProductType[]) => {
    const productsOrderResult = productsData.map((item: ProductType) => {
      return setProductsOrder(item);
    });

    return productsOrderResult.sort((a: any, b: any) => {
      return a.order - b.order;
    });
  };

  return sortProductsByOrder(products).map((item: any) => {
    delete item.order;

    return item;
  });
};
