import Cookies, { CookieAttributes } from 'js-cookie';
import { AKAMAI_EDGESCAPE_COUNTRY } from './constants/akamai';
import {
  FE_USER_CART,
  FE_USER_CART_FAV_ITEM_COUNT,
  FE_USER_CART_FIRST_NAME,
  FE_USER_CART_IS_LOYALTY_MEMBER,
  FE_USER_CART_ITEM_COUNT,
  FE_USER_CART_LOYALTY_LEVEL,
  FE_USER_CART_LOYALTY_LEVEL_NAME,
  FE_USER_CART_LOYALTY_POINTS,
  FE_USER_CART_SIGNED_IN
} from './constants/cart';

export const getCookie = (key: string) => Cookies.get(key);
export const setCookie = (
  name: string,
  value: string,
  options?: CookieAttributes | undefined
) => Cookies.set(name, value, options);

export const getUserCartData = () => {
  const userCartDataCookie = getCookie(FE_USER_CART) || '';
  const cookieDataFormatted =
    userCartDataCookie && userCartDataCookie.replaceAll(':', '=');
  const userCartDataAsURLParams = new URLSearchParams(cookieDataFormatted);

  return Object.fromEntries(userCartDataAsURLParams.entries()) || {};
};

export const getCartItemCount = () =>
  getUserCartData()[FE_USER_CART_ITEM_COUNT] || 0;
export const getSignedInStatus = () =>
  getUserCartData()[FE_USER_CART_SIGNED_IN] === '1' ? true : false;
export const getFirstName = () =>
  getUserCartData()[FE_USER_CART_FIRST_NAME] || '';
export const getLoyaltyMemberStatus = () =>
  getUserCartData()[FE_USER_CART_IS_LOYALTY_MEMBER] === '1' ? true : false;
export const getLoyaltyPoints = () =>
  getUserCartData()[FE_USER_CART_LOYALTY_POINTS] || 0;
export const getLoyaltyLevelName = () =>
  getUserCartData()[FE_USER_CART_LOYALTY_LEVEL_NAME] || '';
export const getLoyaltyLevel = () =>
  getUserCartData()[FE_USER_CART_LOYALTY_LEVEL] || '';
export const getFavItemCount = () =>
  getUserCartData()[FE_USER_CART_FAV_ITEM_COUNT] || '0';

export function getCountryFromCookies() {
  return (getCookie(AKAMAI_EDGESCAPE_COUNTRY) || '').replace('cn=', '');
}
