import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const skuSmallImagesFragment = `
    on ${QueryType.skus} {
        media {
            small {
                src
                alt
                height
                width
                type
            }
        }
    }
`;

export const skuSmallImagesFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: skuSmallImagesFragment
  };
};
