import {
  BuConfig,
  getBusinessUnitConfig
} from '@gemini/shared/services/configuration/business-unit';
import {
  IPageData,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import {
  getProducts,
  ProductType
} from '@gemini/shared/services/products/catalog';
import { getProductsQuery } from '@gemini/shared/services/products/next-sd-prodcat';
import { getLogger } from '@gemini/shared/utils/logger';
import { getDefaultRetryOptions, retry } from '@gemini/shared/utils/retry';
import { getPageData } from './getPageData';
import {
  getCrossSellProducts,
  getIdsForPLP,
  isDataSourcePerlgem,
  isDataSourceStardust
} from './utils';

export type PageAndProductData = {
  products: ProductType[];
  pageData: IPageData;
};

export type NodeData = {
  pageData: IPageData;
};

let buConfig: Promise<BuConfig>;

const getBuConfig = async () => {
  if (isDataSourceStardust()) {
    buConfig = retry(
      getBusinessUnitConfig,
      getDefaultRetryOptions('getBusinessUnitConfig')
    )();
  }

  return buConfig;
};

const getSppId = (data: PageData) => {
  const {
    path,
    product: {
      references: { defaults }
    }
  } = data;
  if (
    isDataSourcePerlgem() &&
    path[0] === 'product' &&
    !isNaN(parseFloat(path[1])) &&
    !isNaN(parseFloat(path[2]))
  ) {
    return `PROD${path[2]}`;
  } else if (isDataSourceStardust()) {
    if (!defaults?.product) {
      throw new Error(`No product id for SPP page: (${defaults?.product})`);
    }

    return defaults.product;
  }

  const error = new Error(`Error getting main product Id for path: ${path}`);
  getLogger<true>().logError(error);

  throw error;
};

type PageData = IPageData & { shouldUpdateInventory: boolean };

const getProductsForPage = async (data: PageData) => {
  const {
    product: { references },
    section,
    type
  } = data;
  const lowerCasedSection = section.toLowerCase();

  const isPLP =
    lowerCasedSection === 'mpp' || type?.toLowerCase() === 'elc_mpp';
  const isSPP = lowerCasedSection === 'spp';
  const isHomePage = lowerCasedSection === 'home';

  let products = [];
  let productArguments: Record<string, string[]> = {};

  const { categories: categoryIds, skus: skuIds } = references;
  let { products: productIds = [] } = references;

  if ((isHomePage || isPLP) && isDataSourceStardust()) {
    productIds = (await getIdsForPLP(data.templates as TemplateType[])).flat(3);
  } else if (isSPP) {
    const productId = getSppId(data);

    productIds = [productId];
  }

  productArguments = {
    categoryIds: isPLP ? categoryIds : [],
    productIds,
    skuIds
  };

  const bConfig = await buConfig;

  const query = isDataSourceStardust()
    ? getProductsQuery({ features: bConfig.features })
    : '';

  products = await retry(
    () =>
      getProducts(productArguments, query, {
        updateInventory: data.shouldUpdateInventory,
        queryKey: isSPP ? 'catalog-spp' : 'catalog-mpp'
      }),
    getDefaultRetryOptions('getProducts')
  )();

  const parsedProducts = JSON.parse(JSON.stringify(products));

  // Cross sell functionality
  if (
    isSPP &&
    parsedProducts[0].crossSell &&
    parsedProducts[0].crossSell.length > 0
  ) {
    const params = {
      query,
      shouldUpdateInventory: data.shouldUpdateInventory
    };
    const parsedCrossSellProducts = await getCrossSellProducts(
      parsedProducts[0].crossSell,
      params
    );

    return parsedProducts.concat(parsedCrossSellProducts);
  }

  return parsedProducts;
};

export const getPageAndProductData = async (
  pageDataRequestURL: string,
  shouldUpdateInventory: boolean,
  path?: string[]
): Promise<PageAndProductData> => {
  getBuConfig();

  const pageData = await retry(
    () => getPageData(pageDataRequestURL),
    getDefaultRetryOptions('getPageData')
  )();

  const pageDataErr = pageData?.error;
  if (pageDataErr) {
    throw new Error(
      `${pageDataErr.code} for ${pageDataRequestURL}: ${pageDataErr.message}`
    );
  }
  if (!pageData.product) {
    throw new Error(
      `pageData product key is falsy: (${pageData.product}). URL: ${pageDataRequestURL}`
    );
  }

  const products = await getProductsForPage({
    ...pageData,
    path,
    shouldUpdateInventory
  });

  if (!products || !products.length) {
    throw new Error(`no products on page: ${pageDataRequestURL}`);
  }

  return {
    products,
    pageData
  };
};
