import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const vtoCrossSellFragment = `
    on ${QueryType.skus} {
        cross_sell {
            categories {
                sku_id
                position
            }
        }
    }
`;

export const vtoCrossSellFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: vtoCrossSellFragment
  };
};
