import { NextApiRequest, NextApiResponse } from 'next';
import { getLogger } from '@gemini/shared/utils/logger';
import { jsonRPCFetch } from './jsonRPCFetch';

export const jsonRPCHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const { body: request, headers, query } = req;
  try {
    const dbgmethod = query.slug as string;

    delete headers.host;

    const response: any = await jsonRPCFetch({
      url: '',
      request,
      headers,
      dbgmethod
    });

    if (response) {
      const cookies = (response.headers as any).raw()['set-cookie'];
      res.setHeader('Set-Cookie', cookies);
      const jsonResponse = await response.json();

      res.json(jsonResponse);
    } else {
      throw new Error('Network response not ok.');
    }
  } catch (error) {
    getLogger<true>().logError(error as Error);
    res.status(500).send({ error });
  }
};

export default jsonRPCHandler;
