import Script from 'next/script';
import {
  JS_REPO_HREF,
  JS_REPO_ORIGIN,
  localBundles,
  remoteBundles
} from './BundlesPathsUtils';

export const ReplaceSrc = () => {
  return (
    <Script id="replaceSrc">
      {`
        const root = typeof window !== 'undefined' ? window : global || {};

        function replaceFunction(originalSrc) {
          const elcSrcIndex = originalSrc.indexOf('elc-');
          const pgSrcIndex = originalSrc.indexOf('pg-');
          const sourceInfo = originalSrc.slice(elcSrcIndex !== -1 ? elcSrcIndex : pgSrcIndex);
          const [serviceName, chunkName] = sourceInfo.split('/');
          const serviceVersion = root.ServiceRegistry[serviceName].version;
          let url = '${JS_REPO_HREF}/';

          const localBundles = ${JSON.stringify(localBundles)};
          const localBundle = localBundles[serviceName];
          if (localBundle) {
            url = localBundle;
          }

          const remoteBundles = ${JSON.stringify(remoteBundles)};
          const remoteBundle = remoteBundles[serviceName];
          if (remoteBundle) {
            url = \`${JS_REPO_ORIGIN}/\${remoteBundle}/\`;
          }

          return [url, serviceName, '@', serviceVersion, '/', chunkName].join('');
        }

        (function() {
          root.replaceSrc = replaceFunction;
        })();
      `}
    </Script>
  );
};
