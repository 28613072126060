import { IAsset, IVideoAsset } from './Asset';

export enum ImageSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export type IMediaType = IVideoAsset | IAsset;

export interface IMedia {
  small: IAsset[];
  medium: IAsset[];
  large: IAsset[];
  smoosh?: IAsset[];
  model?: IAsset[];
  videos?: IVideoAsset[];
  responsive?: IAsset[];
}
