import { root } from '@gemini/shared/services/configuration/utils';

export function tealiumLink(data: any) {
  if ((root as any).utag && typeof (root as any).utag.link === 'function') {
    (root as any).utag.link(data);
  }
}

export function tealiumView() {
  if ((root as any).utag && typeof (root as any).utag.view === 'function') {
    (root as any).utag.view();
  }
}
