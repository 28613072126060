import { Atom, useAtomValue } from 'jotai';
import { FC, PropsWithChildren } from 'react';

export interface IWithInitAtomProps {
  atom: Atom<boolean>;
}

export const WithInitAtom: FC<PropsWithChildren<IWithInitAtomProps>> = ({
  atom,
  children
}) => {
  const initialized = useAtomValue(atom);

  return initialized ? <>{children}</> : null;
};
