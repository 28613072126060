import type { NextApiRequest, NextApiResponse } from 'next';
import { getBundleUrl } from '@gemini/shared/services/utils/js-repo';
import { getLogger } from '@gemini/shared/utils/logger';
import { isMFEnabled } from '@gemini/shared/services/utils/global';
import { SD_DEPENDENCIES } from '../components/EcommScripts';

export const getSDBundleUrl = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  try {
    if (isMFEnabled()) {
      const { bundle = [] } = req.query;
      const serviceName = bundle[0].replace('fe-', '');

      const bundleKey = SD_DEPENDENCIES.find(lib => lib.match(serviceName));

      if (bundleKey) {
        res.redirect(307, getBundleUrl(bundleKey));
      } else {
        throw new Error(`bundle path was not found as a dependency: ${req.query.bundle}`);
      }
    } else {
      res.setHeader('Content-Type', 'text/javascript');
      res.status(200).send('(function() {})');
    }
  } catch (error) {
    getLogger<true>().logError(error as Error, `Failed redirecting SD bundle: ${req.query.bundle}`);

    res.status(500).send({});
  }
};

export default getSDBundleUrl;
