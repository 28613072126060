import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const formulatedWithoutFragment = `
    on ${QueryType.skus} {
        iln_formulated_without
    }
`;

export const formulatedWithoutFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: formulatedWithoutFragment
  };
};
