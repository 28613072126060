import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const keyIngredientsCollectionFragment = `
    on ${QueryType.product} {
        key_ingredients {
            key
            value
        }
    }
`;

export const keyIngredientsCollectionFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: keyIngredientsCollectionFragment
  };
};
