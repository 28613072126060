import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const expirationDateFragment = `
    on ${QueryType.skus} {
        expiration_date
    }
`;

export const expirationDateFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: expirationDateFragment
  };
};
