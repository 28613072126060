import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const freeFromFragment = `
    on ${QueryType.product} {
        free_from {
            key
            value
        }
    }
`;

export const freeFromFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: freeFromFragment
  };
};
