import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const productShadeGroupsFragment = `
    on ${QueryType.product} {
        shade_groups {
            key
            value
            position
        }
    }
`;

const skuShadeGroupsFragment = `
    on ${QueryType.skus} {
        shade_groups {
            key
            value
        }
    }
`;

export const shadeGroupsFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productShadeGroupsFragment,
    [QueryType.skus]: skuShadeGroupsFragment
  };
};
