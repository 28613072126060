import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const undertoneFragment = `
    on ${QueryType.skus} {
        undertone {
            value
        }
    }
`;

export const undertoneFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: undertoneFragment
  };
};
