import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const preorderFragment = `
    on ${QueryType.skus} {
        preorder_date
        is_preorderable
    }
`;

export const preorderFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.skus]: preorderFragment
  };
};
