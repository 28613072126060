import type { NextApiRequest, NextApiResponse } from 'next';
import { getLogger } from '@gemini/shared/utils/logger';

export const getPageDataHandler = async <T>(
  req: NextApiRequest,
  res: NextApiResponse,
  pageDataFetcher: (url: string) => Promise<T>
) => {
  let codeError = 500;

  try {
    const encodedUrl = JSON.parse(req.body || '');

    if (!req.body || !encodedUrl) {
      codeError = 400;
      throw new Error(`URL missing on body: (${req.body})`);
    }

    const url = decodeURI(encodedUrl);

    const pageData = await pageDataFetcher(url);

    res.json(pageData);
  } catch (error) {
    getLogger<true>().logError(error as Error);
    res.status(codeError).send({ error });
  }
};

export default getPageDataHandler;
