import { IQueryTypeFragments } from '../QueryFragments';
import { QueryType } from './Default';

const formFragment = `
    on ${QueryType.product} {
        form {
            key
            value
        }
    }
`;

export const formFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: formFragment
  };
};
