import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const subHeaderFragment = `
    on ${QueryType.product} {
        sub_header
    }
`;

export const subHeaderFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: subHeaderFragment
  };
};
