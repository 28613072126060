import { IQueryTypeFragments } from '../QueryFragments';

export enum QueryType {
  skus = 'product__skus',
  product = 'product',
  collection = 'product_collection'
}

const productDefaultFragment = `
    on ${QueryType.product} {
        default_category {
            id
            key
            value
        }
        description
        display_name
        is_hazmat
        meta {
            description
        }
        product_badge
        product_id
        product_url
        short_description
        tags {
            total
            items {
                id
                value
                key
            }
        }
    }
`;

const skuDefaultFragment = `
    on ${QueryType.skus} {
        is_default_sku
        is_discountable
        is_giftwrap
        is_under_weight_hazmat
        iln_listing
        iln_version_number
        inventory_status
        is_engravable
        material_code
        prices {
            currency
            is_discounted
            include_tax {
                price
                original_price
                price_per_unit
                price_formatted
                original_price_formatted
                price_per_unit_formatted
            }
        }
        sizes {
            value
            key
        }
        shades {
            name
            description
            hex_val
        }
        sku_id
        sku_badge
        unit_size_formatted
        upc
    }
`;

export const defaultFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: productDefaultFragment,
    [QueryType.skus]: skuDefaultFragment
  };
};
