import { emitAnalyticsEvent } from '@gemini/shared/services/analytics/sd-analytics';
import { ServiceBusTopics } from '@gemini/shared/services/communication/next-elc-service-bus';
import { root } from '@gemini/shared/services/configuration/utils';

export function initializeOneTrust() {
  const onOneTrustLoaded = async () => {
    void emitAnalyticsEvent(ServiceBusTopics.events.ANALYTICS_LOADED);
  };

  // this is called from Tealium after onetrust changes
  (root as unknown as { onOneTrustLoaded: () => unknown }).onOneTrustLoaded =
    onOneTrustLoaded;
}
