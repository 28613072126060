export enum serviceStatuses {
  UNKNOWN = 'unknown',
  STARTED = 'started',
  FAILED = 'failed'
}

export let analyticsServiceStatus: serviceStatuses = serviceStatuses.UNKNOWN;

export const updateAnalyticsServiceStatus = (state: serviceStatuses) => {
  analyticsServiceStatus = state;
};

export const ensureAnalyticsServiceStarted = async () => {
  const analyticsReady = () =>
    analyticsServiceStatus === serviceStatuses.STARTED;

  return new Promise<void>((resolve, reject) => {
    if (analyticsReady()) {
      resolve();
    } else if (analyticsServiceStatus === serviceStatuses.FAILED) {
      reject();
    } else {
      const interval = setInterval(() => {
        if (analyticsReady()) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    }
  });
};
