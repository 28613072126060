import Cookies from 'js-cookie';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { AnalyticsService } from '@gemini/shared/services/analytics/next-elc-analytics';
import { ServiceBus } from '@gemini/shared/services/communication/next-elc-service-bus';
import { AppProvider } from '@gemini/shared/services/configuration/app-client';
import { getWrapperData } from '@gemini/shared/services/configuration/app-server';
import { getBusinessUnitConfig } from '@gemini/shared/services/configuration/business-unit';
import {
  EcommListener,
  EcommScripts,
  ElcRuntime
} from '@gemini/shared/services/content/next-elc-ecomm';
import { mantleCMSFetch } from '@gemini/shared/services/content/next-mantlecms-server';
import { isProd } from '@gemini/shared/services/utils/global';
import { NextOptimizely } from '@gemini/shared/services/utils/next-optimizely';
import { DevTools } from '@gemini/shared/ui/templates/dev-tools';
import { ErrorBoundary } from '@gemini/shared/ui/utils/error-boundary';
import { getLogger } from '@gemini/shared/utils/logger';
import { retry } from '@gemini/shared/utils/retry';
import '../../../../libs/brand/el/base-theme/src/tailwind-bootstrap.scss';
import { ALLOWED_PATHS } from '../utils/constants/allowed-paths';

interface IAppProps extends AppProps {
  globalConfig: object;
}

// @todo move this back under the production gate after mocks are not a dependency for production builds
if (process.env.NEXT_PUBLIC_MOCK_DATA_ENABLED) {
  require('@gemini/mock-data');
}

if (!isProd()) {
  Cookies.set('ELC_API_HOST', '/api');
  Cookies.set('ELC_API_AUTH', process.env.NEXT_PUBLIC_MANTLE_CMS_AUTH_COOKIE);
}

function GeminiApp({ Component, pageProps, globalConfig }: IAppProps) {
  getLogger();

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <AppProvider value={{ ...globalConfig }}>
        <ErrorBoundary>
          <>
            {!isProd() && <DevTools />}
            <EcommListener />
            <ElcRuntime />
            <ServiceBus />
            <AnalyticsService inEcommContext={true} />
            <EcommScripts />
            <Component {...pageProps} />
            <NextOptimizely />
          </>
        </ErrorBoundary>
      </AppProvider>
    </>
  );
}

GeminiApp.getInitialProps = async function () {
  const [wrapperData, buidConfig] = await Promise.all([
    retry(getWrapperData, {
      retryAmount: 2,
      awaitMilliseconds: 250,
      functionName: 'getWrapperData'
    })(),
    retry(getBusinessUnitConfig, {
      retryAmount: 2,
      awaitMilliseconds: 250,
      functionName: 'getBusinessUnitConfig'
    })()
  ]);
  const {
    app_config: appConfig,
    languages,
    countries,
    site_config: siteConfig,
    translations,
    templates
  } = wrapperData || {};

  const pick = <T,>(obj: T, ...k: (keyof T | string)[]): Partial<T> =>
    k.reduce((acc, name: keyof T) => {
      acc[name] = obj[name];

      return acc;
    }, {} as Partial<T>);

  const globalConfig = {
    allowedPaths: ALLOWED_PATHS,
    appConfig,
    siteConfig,
    languages,
    countries,
    translations: {
      elc_product: translations.elc_product,
      // minimum set of translations for now
      elc_general: {
        ...pick(
          translations.elc_general as Record<string, unknown>,
          'next',
          'previous',
          'close',
          'share'
        ),
        // TODO - this does not exist in elc_general - added here to get the ball rolling
        play: 'Play',
        menu: 'Menu'
      },
      elc_cart: {
        ...pick(
          translations.elc_cart as Record<string, unknown>,
          'shopping_bag',
          'added_to_cart'
        )
      },
      elc_search: pick(translations.elc_search, 'search'),
      lang: translations.lang,
      product: translations.product
    },
    templates: { cartContent: templates?.gnav_cart_content },
    revisionTags: undefined,
    ...buidConfig
  };

  if (!isProd()) {
    const revTagListUrl = `${process.env.MANTLE_CMS_URL}/api/utils/v2/revision-tags?show_inactive=true&limit=20`;
    const revTagListData = await mantleCMSFetch(revTagListUrl);

    globalConfig.revisionTags = revTagListData;
  }

  return {
    globalConfig
  };
};

export default GeminiApp;
