import * as Sentry from '@sentry/nextjs';
import { ErrorInfo } from 'react';

export class ServerLogger {
  private data: any = null;

  public setEnvData = (data: any) => {
    this.data = data;
  };

  public logError = (error: Error, message?: string, infoError?: ErrorInfo) => {
    const log = {
      error,
      message,
      envData: this.data,
      ...infoError
    };
    Sentry.captureException(error, { extra: { message, infoError } });
    this.onLogs([log]);
  };

  public onLogs = (logs: unknown[]) => {
    // checkout for https://github.com/pinojs/pino/
    // and create child process for logs
    // eslint-disable-next-line no-console
    logs.forEach(console.error);
  };
}

let serverLogger: ServerLogger;

export function getLogger(): ServerLogger {
  if (!serverLogger) {
    serverLogger = serverLogger || new ServerLogger();
  }

  return serverLogger;
}
