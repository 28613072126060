import { root } from '@gemini/shared/services/configuration/utils';
import { getCookie, setCookie } from '@gemini/shared/utils/browser-storage';
import { routeEvent } from './events/pgEventRouter';

export const DATALAYER_EVENTS_COOKIE = 'datalayer_events';

function clearEvents() {
  const dataLayerEventsCookie = getCookie(DATALAYER_EVENTS_COOKIE) as string;
  if (dataLayerEventsCookie) {
    setCookie(DATALAYER_EVENTS_COOKIE, '0', { expires: 0 });
  }

  (root as any).utag_data.datalayer_events = {};
}

export function processAnalyticsEvents(data: any) {
  const events = data.datalayer_events;
  delete data.datalayer_events;

  if (events) {
    Object.keys(events).map((eventName) => {
      if (events[eventName].event_data) {
        routeEvent(eventName, { ...data, ...events[eventName].event_data });
      } else {
        routeEvent(eventName);
      }
    });

    clearEvents();
  }
}
