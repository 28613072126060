import type { GetStaticPropsContext } from 'next';
import type { IPageData } from '@gemini/shared/services/content/next-mantlecms';
import {
  getPageDataRequestURL,
  mantleCMSFetch
} from '@gemini/shared/services/content/next-mantlecms-server';

export async function getPageData(url: string): Promise<IPageData> {
  const pageData = await mantleCMSFetch(url);

  return pageData;
}

export const getPageUrl = (context: GetStaticPropsContext) => {
  const reqPath =
    typeof context.params?.path !== 'string'
      ? context.params?.path?.join('/')
      : '/';

  return {
    reqPath,
    path: context.params?.path,
    url: getPageDataRequestURL({
      context,
      params: {
        urls: reqPath || '/'
      },
      includeMobileData: true
    })
  };
};
