import { IQueryTypeFragments } from '../../QueryFragments';
import { QueryType } from '../Default';

const brushTypesFragment = `
    on ${QueryType.product} {
        brush {
            types {
                key
                value
            }
        }
    }
`;

export const brushTypesFragments = (): IQueryTypeFragments => {
  return {
    [QueryType.product]: brushTypesFragment
  };
};
