import { getAccessToken } from '@gemini/shared/services/access/guest';
import { isBrowser } from '@gemini/shared/services/utils/global';
import { getLogger } from '@gemini/shared/utils/logger';

interface IBusinessUnitConfig {
  features: any;
  account: {
    care: any;
    profileApp: any;
  };
}

export type BuConfig = Omit<IBusinessUnitConfig, 'account'>;

export async function getBusinessUnitConfig(): Promise<BuConfig> {
  const origin = `https://www.${process.env.NEXT_PUBLIC_HOST}`;
  const accessToken = getAccessToken(`${process.env.NEXT_PUBLIC_BUID}`, origin);

  const envPrefix =
    process.env.API_ENV_BUID_CONFIG === 'prod'
      ? ''
      : `${process.env.API_ENV_BUID_CONFIG}.`;
  const buidConfigURL = `https://${envPrefix}${process.env.NEXT_PUBLIC_STARDUST_REGION}.sdapi.io/stardust-buid-configurations-v1/configurations/${process.env.NEXT_PUBLIC_BUID}`;

  const headers: {
    [key: string]: any;
  } = new Headers({
    'Content-Type': 'application/json',
    'business-unit': `${process.env.NEXT_PUBLIC_BUID}`,
    clientid: `${process.env.NEXT_PUBLIC_CLIENT_ID}`,
    authorizationtoken: accessToken,
    Origin: origin
  });

  if (!isBrowser()) {
    headers.append(
      'cookie',
      `${headers.cookie}; ${process.env.NEXT_COOKIE_BYPASS_WAF}`
    );
  }

  const response = await fetch(buidConfigURL, {
    method: 'GET',
    headers
  });

  if (!response.ok) {
    const error = new Error(
      `error getting buConfig, status text: ${response.statusText}`
    );
    getLogger().logError(error);

    return {} as BuConfig;
  }

  const businessUnitConfig: IBusinessUnitConfig = await response.json();

  // cleanup/remove irrelevant data
  delete businessUnitConfig.account.care;
  delete businessUnitConfig.account.profileApp;

  return businessUnitConfig;
}
