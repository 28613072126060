import { IFeatures } from './Features';
import { QueryType } from './features/Default';
import { getEnabledFragments, getQueryFragments } from './QueryFragments';

export interface IGetProductsQuery {
  features: Partial<IFeatures>;
}

export const getProductsQuery = (config: IGetProductsQuery) => {
  const enabledFragments = getEnabledFragments(config);
  const queryFragments = getQueryFragments(config);

  const fragmentDefinitionsQuery = Object.keys(enabledFragments)
    .reduce<string[]>((acc, name) => {
      const featureFragmentDefinition =
        enabledFragments[name as keyof typeof enabledFragments];

      if (featureFragmentDefinition) {
        acc.push(`fragment ${name} ${featureFragmentDefinition.trim()}`);
      }

      return acc;
    }, [])
    .join('\n');

  return `
query ProductQuery($productIds: [String!])
  {
    products(environment: { prod: true }, filter: [{ product_id: { in: $productIds } }], sort: []) {
      ${queryFragments[QueryType.collection] ?? ''}
      items {
        ${queryFragments[QueryType.product]}
        skus {
          total
          items {
            ${queryFragments[QueryType.skus]}
          }
        }
      }
    }
  }    
  ${fragmentDefinitionsQuery}
  `;
};
