export interface IProductGridConfig {
  carouselSettings: ICarouselSettings;
  componentElementsOrderConfig: IComponentElementsOrderConfig;
  content: IContentBlock[];
  ctaType: string;
  enableAccordion: boolean;
  enableCategoryHeader: boolean;
  enableFilters: boolean;
  enableGridCarousel: boolean;
  enableHoverInteraction: boolean;
  enableLearnMoreLink: boolean;
  enableMobileQuickView: boolean;
  enableNumberShades: boolean;
  enableProductDetailLink: boolean;
  enableQuickView: boolean;
  enableQuickViewShortcut: boolean;
  enableShadePicker: boolean;
  enableSizePicker: boolean;
  enableVerticalFilters: boolean;
  filterData?: IFilterData;
  filters?: IFiltersConfig;
  gridActionsBar: {
    type: string;
  };
  hideProductBadge: boolean;
  hideProductPrice: boolean;
  hideProductPriceRange: boolean;
  hideReadReviewsLink: boolean;
  hideSorting: boolean;
  items: IGridItem[];
  lazyLoadRootMargin: string;
  lazyloadProductGridItems: boolean;
  mppDefaultCtaOption: string;
  productCarouselEnableSizePicker: boolean;
  productGridBreakpoints: IGridBreakpoints;
  productGridEnableSizePicker: boolean;
  productGridHeader: string | null;
  productGridSubheader: string | null;
  renderMethod: string;
  showDynamicCategoryHeader: boolean;
  showSubDisplayName: boolean;
  showToosMessage: boolean;
  showToosTitle: boolean;
  viewableInventoryStatuses: string[];
}

export interface ICarouselSettings {
  arrows: boolean;
  dots: boolean;
  infinite: boolean;
  responsive: ICarouselSettingsResponsive[];
  slidesToScroll: number;
  slidesToShow: number;
}

export interface ICarouselSettingsResponsive {
  breakpoint: number;
  settings: ICarouselSettingsResponsiveSettings;
}

export interface ICarouselSettingsResponsiveSettings {
  slidesToScroll: number;
  slidesToShow: number;
}

export interface IContentBlock {
  nId: number;
  nPsn: number;
  nSize: number;
}

export interface IGridItem {
  params: {
    id: string;
  };
  type: string;
}

export interface IGridBreakpoints extends IBreakpointColumns {
  [key: string]: IBreakpointColumns | IBreakpointScreens | number;
}

export interface IBreakpointColumns {
  numColumnsDesktop: number;
  numColumnsExtraLargeScreen: number;
  numColumnsLargeScreen: number;
  numColumnsMobile: number;
}

export interface IBreakpointScreens {
  Desktop: number;
  ExtraLargeScreen: number;
  LargeScreen: number;
  Mobile: number;
}

export enum DisplayType {
  accordion = 'accordion',
  tab = 'tab',
  default = 'default'
}

export interface IOrderData {
  [key: string]: string;
}

export interface IOrderParams {
  [key: string]: unknown;
}

export interface IOrderConfigs {
  weight: number;
  elements: IOrderConfig;
  display?: DisplayType;
  data?: IOrderData;
  params?: IOrderParams;
}

export interface IOrderConfig {
  [key: string]: IOrderConfigs;
}

interface IComponentElementsOrderConfig {
  [key: string]: IComponentElementsOrderConfig | IOrderConfig;
}

export interface IFilterData {
  filtersTitle: string;
  filterSets: IFilterSets[];
  generalKeys: IGeneralKeys[];
}

export interface IGeneralKeys {
  key: string;
  label: string;
}

export enum FilterQueryLevel {
  SKU = 'sku',
  PRODUCT = 'product'
}

export interface IFilterMapItem {
  key_type: FilterQueryKeyType;
  key_name: string;
}

export interface IFilterQuery {
  level: FilterQueryLevel;
  map: IFilterMapItem[];
}

export enum FilterQueryKeyType {
  MACHINE_NAME = 'machine_name',
  ARRAY_OF_OBJECTS = 'array_of_objects',
  OBJECT = 'object'
}

export enum FilterQueryLogic {
  AND = 'AND',
  OR = 'OR'
}

export interface IFilterSets {
  items: IGeneralKeys[];
  label: string;
  key: string;
  logic: FilterQueryLogic;
  level: FilterQueryLevel;
  type: FilterSetType;
  query: IFilterQuery[];
  isRange: boolean;
}

interface IBreakpoint {
  breakpoint: number;
  settings: {
    filterSets?: IFilterSets;
  };
}

export enum FilterOptionTypes {
  CHIPS = 'chips',
  CHECKBOX = 'checkbox',
  LIST = 'list'
}

export enum FilterSetType {
  TOGGLES = 'toggles',
  ACCORDIONS = 'accordions',
  LABELS = 'labels'
}

export enum SelectedFilterValues {
  BUTTONS = 'buttons',
  LABELS = 'labels'
}

export interface IOrderConfigCollection {
  [key: string]: IOrderConfig;
}

export interface IResponsiveSettings {
  breakpoint: number;
  settings: {
    enabled?: boolean;
    [key: string]: boolean | number | undefined;
  };
}

export interface IFilterSetsConfig {
  type: FilterSetType;
  options: boolean;
}

export interface IFiltersSettings {
  enabled: boolean;
  allowAllFiltersCollapsed?: boolean;
  filterOptions: {
    type: FilterOptionTypes;
  };
  selectedFilterValues: {
    type: SelectedFilterValues;
  };
  filterSets: IFilterSetsConfig;
  closeOnOverlayMaskClick?: boolean;
  showIndividualFilterByLabel?: boolean;
}

export interface IFiltersConfig extends IFiltersSettings {
  filterBy?: boolean;
  componentElementsOrderConfig: IOrderConfigCollection;
  responsive?: IResponsiveSettings[];
}
