export function isNodeWindow(node: Element | Window) {
  return (node as Window).self === window;
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function isProd() {
  return (
    !process.env.NEXT_PUBLIC_APP_ENV ||
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
  );
}

export function isLocalDev() {
  if (isBrowser()) {
    return window.location.host.includes('localhost');
  }

  return false;
}

export function isSetToAllRoutesExternal() {
  return Boolean(process.env.NEXT_PUBLIC_SET_ALL_LINKS_EXTERNAL);
}

export function noop() {
  return;
}

export function isCartOverlayDrawer() {
  return process.env.CART_OVERLAY_TYPE === 'drawer';
}

export function isCartOverlayTooltip() {
  return process.env.CART_OVERLAY_TYPE === 'tooltip';
}

export function isMFEnabled() {
  return process.env.NEXT_PUBLIC_ENABLE_MF;
}

export function formattedResponseError(
  url: string,
  res: Response,
  error: Error
) {
  throw new Error(
    JSON.stringify({
      status: res.status,
      statusText: res.statusText,
      url,
      body: res.text ? res.text() : 'No response body',
      error
    })
  );
}

export async function responseHandler(url: string, res: Response) {
  try {
    return await res.json();
  } catch (error) {
    formattedResponseError(url, res, error as Error);
  }
}

export function isJsonResponse(response: string) {
  try {
    JSON.parse(response);
  } catch (e) {
    return false;
  }

  return true;
}
