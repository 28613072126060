import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { root } from '@gemini/shared/services/configuration/utils';
import { updateDataLayer } from './updateDataLayer';

function isDev() {
  return process.env.NEXT_PUBLIC_ANALYTICS_ENV?.match(/^prod$/i) ? false : true;
}

export function getDataLayer() {
  return (root as any).utag_data;
}

export function registerPageInDataLayer(
  page: string,
  payload?: { products?: any[] }
) {
  let pageType = '';
  let pageName = page;

  switch (pageName) {
    case 'MPP':
      pageName = `${pageName} | ${
        (payload?.products && payload?.products[0]?.default_category?.value) ||
        ''
      }`; // TODO: this needs to be updated to show breadcrumbs? validate with John
      pageType = 'category';
      break;
    case 'SPP':
      pageName = `${pageName} | ${
        (payload?.products && payload?.products[0]?.display_name) || ''
      }`;
      pageType = 'product';
      break;
    case 'home':
    case 'account':
    case 'search':
    case 'cart':
    case 'checkout':
    case 'order':
      pageType = pageName;
      break;
    default:
      pageType = 'category';
      break;
  }

  updateDataLayer({
    page_name: pageName,
    page_type: pageType
  });
}

export function generateInitialDataLayer() {
  const {
    brand: { literal: brandName },
    checkout: {
      validation: { currency }
    },
    country: { abbrev: countryCode },
    language: { abbrev: languageCode }
  } = useAppContext();

  const countryCodeUpperCase = (countryCode || '').toUpperCase();

  const dataLayer = {
    //active_checkout_panel: 'TBD', // might be deprecated, check tealium
    brand: brandName,
    brand_id: brandName,
    brand_locale: `${brandName}|${countryCodeUpperCase}`,
    //brand_locale_evar2: `${brandName}|${countryCodeUpperCase}`, // might be deprecated, check tealium
    brand_locale_space: `${brandName} ${countryCodeUpperCase}`,
    //category_id: 'TBD', // might be deprecated, check tealium
    //category_name: 'TBD', // might be deprecated, check tealium
    country_code: countryCodeUpperCase,

    // this is coming through incorrectly from useAppContext - coming through as GB (instead of US), so, commenting out to default to PG data
    //currency_code: currency,
    ELC_ENV: isDev() ? 'DEV' : 'PROD',
    language_code: languageCode,
    locale: `${languageCode}_${countryCodeUpperCase}`,
    platform: 'gemini'
    //page_name: 'TBD',
    //page_template: 'TBD', // might be deprecated, check tealium
    //page_type: 'TBD'
  };

  return dataLayer;

  /*
  Sample data from Lauder US Prod
  ELC_ENV: "PROD"
active_checkout_panel: null
category_id: "home"
category_name: "home"
page_name: "home"
page_template: "home"
page_type: "home"
*/

  //replicate this stuff:

  /*
        '%page_info_data' => {
            'data_map' => {
                'page_info' => {
                    'category_id' => 'categoryID',
                    'category_name' => 'categoryName',
                    'page_name' => 'pageID',
                    'page_template' => 'pageType',
                    'page_type' => 'type',
                    'active_checkout_panel' => 'active_checkout_panel',
                },
            },
        },

        '%meta_data' => {
            'data_map' => {
                'site_info' => {
                    'brand' => 'BRAND_ID',
                    'brand_id' => 'BRAND_ID',
                    'country_code' => 'COUNTRY_ID',
                    'language_code' => 'LANGUAGE_ID',
                    'locale' => 'LOCALE',
                    'brand_locale' => 'BRAND_LOCALE',
                    'brand_locale_space' => 'BRAND_LOCALE_SPACE',
                    'brand_locale_evar2' => 'BRAND_LOCALE',
                },
                'self' => {
                    'ELC_ENV' => 'isDevEnv',
                },
                'util' => {
                    'currency_code' => 'dataLayerCurrencyCode',
                },
            },
        },


*/
}
