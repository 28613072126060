import type { NextApiRequest, NextApiResponse } from 'next';
import { getBusinessUnitConfig } from '@gemini/shared/services/configuration/business-unit';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getProductsQuery } from '@gemini/shared/services/products/next-sd-prodcat';
import { isJsonResponse } from '@gemini/shared/services/utils/global';
import { getLogger } from '@gemini/shared/utils/logger';
import { retry } from '@gemini/shared/utils/retry';
import { getProducts, Options } from './getProducts';

export const getProductsHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  let codeError = 500;

  try {
    if (!isJsonResponse(req.body)) {
      throw new Error(
        `Not a valid json request body when fetching products: (${req.body})`
      );
    }

    const productIds = JSON.parse(req.body || '');

    if (!req.body || !productIds || productIds.length < 1) {
      codeError = 400;
      throw new Error(`No products were requested: (${req.body})`);
    }

    const isPLP = productIds.length > 1;
    const options: Options = {
      updateInventory: false,
      queryKey: isPLP ? 'catalog-mpp' : 'catalog-spp'
    };

    const buConfig = await retry(() => getBusinessUnitConfig(), {
      retryAmount: 2,
      awaitMilliseconds: 250,
      functionName: 'getBusinessUnitConfig'
    })();

    const products = await getProducts(
      {
        productIds: productIds.map(String)
      },
      getProductsQuery({ features: buConfig.features }),
      options
    );

    res.json(products);
  } catch (error) {
    getLogger<true>().logError(error as Error);
    res.status(codeError).send({ error });
  }
};

export default getProductsHandler;
